import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useModal } from '../../../../hooks/useModal.hook';
import { useOutsideClick } from '../../../../hooks/useOutsideClick.hook';
import { ModalContainerLayout } from '../../../../styles/modal/InfoModal.styled';
import ModalInteraction from '../../../../components/Interection/ModalInteraction';
import {
  P3GreyTextStyled,
  P3SheetGreystyled,
  P3SheetwhiteStyled,
  P3WhiteTextStyled_mb,
} from '../../../../styles/text/P3Text.styled';
import { InModalGreyBorderLine } from '../../../../styles/line/BorderLine.styled';
import { btnMain, btnModalGrey } from '../../../../styles/btn/Btn.styled';
import { useConfirm } from '../../../../hooks/useConfirm.hook';
import BasicBox from '../../../../components/box/basicBox/BasicBox';
import BackButton from '../../../../components/button/BackButton';
import PWPageInteraction from '../../../../components/Interection/PWSettingPageInteraction';
import CustomPinInput from '../../../../contents/pin';
import Lottie from 'lottie-react';
import BasicButton from '../../../../components/button/ConfirmButton';
import { motion } from 'framer-motion';
import LottieLoading from '../../../../styles/icons/Loading.json';
import { btnLoading } from '../../../../styles/loading/Loading.styled';

const PasswordChangeSettingInput = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const pinInputRef = useRef<HTMLInputElement>(null);
  const modalRef = useRef<HTMLDivElement>(null);
  const [pinsave, setPinSave] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const [isInputDisabled, setIsInputDisabled] = useState(false);
  const [confirmBtnDisabled, setConfirmBtnDisabled] = useState<boolean>(true);

  const [cleanFlag, setCleanFlag] = useState<boolean>(false);
  const { modalIsOpen, openModal, closeModal } = useModal();

  const [showBackButton, setShowBackButton] = useState(true);

  const { SettingPassword } = location.state || {};

  const closeModalHandler = () => {
    setCleanFlag((current) => !current);
    setConfirmBtnDisabled(true);
    closeModal();
  };

  useOutsideClick(modalRef, closeModalHandler, modalIsOpen);

  const goToPrevious = () => navigate('/password/change/setting');

  useEffect(() => {
    if (pinInputRef.current) {
      pinInputRef.current?.focus();
    }
  }, [pinInputRef]);

  useEffect(() => {
    console.log('설정한 비번', SettingPassword);
  }, [SettingPassword]);

  const completeHandler = (value: string) => {
    setPinSave(value);
    if (value.length >= 4) {
      setConfirmBtnDisabled(false);
    } else {
      setConfirmBtnDisabled(true);
    }
  };

  const handleSummit = async () => {
    setIsLoading(true);
    setShowBackButton(false);
    setIsInputDisabled(true);

    if (SettingPassword !== pinsave) {
      openModal();
      setShowBackButton(true);
      setIsLoading(false);
      setIsInputDisabled(false);
      return;
    }

    navigate('/password/change', {
      state: {
        SettingPassword: SettingPassword,
      },
    });
  };

  const modalConfirmHandler = () => {
    setCleanFlag((current) => !current);
    setConfirmBtnDisabled(true);
    closeModal();
  };

  const confirmAction = useConfirm('', handleSummit, goToPrevious);

  return (
    <div className="flex items-center justify-center h-[100dvh] w-screen">
      <BasicBox>
        <BackButton show={showBackButton} onClick={goToPrevious} />
        <PWPageInteraction
          PageTransition={{
            initial: { opacity: 0.5 },
            animate: {
              opacity: 1,
              transition: {
                ease: [0.6, 0.9, 0.6, 0.5],
              },
            },
          }}
        >
          <div className={P3WhiteTextStyled_mb.class}>비밀번호 확인</div>
          <div className={P3GreyTextStyled.class}>
            입력한 비밀번호를 다시 한번 입력해주세요
          </div>

          <CustomPinInput
            length={4}
            onClean={cleanFlag}
            onPinsave={completeHandler}
            isInputDisabled={isInputDisabled}
          />
        </PWPageInteraction>

        {modalIsOpen && (
          <div
            className={`${ModalContainerLayout.class} pointer-events-auto`}
            onClick={(e) => e.stopPropagation()}
            onTouchStart={(e) => e.stopPropagation()}
          >
            <ModalInteraction
              ref={modalRef}
              ModalVariants={{
                hidden: {
                  y: '100vh',
                  opacity: 0,
                },
                visible: {
                  y: 0,
                  opacity: 1,
                  transition: { type: 'spring', stiffness: 250, damping: 30 },
                },
              }}
            >
              <h2 className={P3SheetwhiteStyled.class}>
                비밀번호가 일치하지 않습니다
              </h2>
              <p className={P3SheetGreystyled.class}>입력하신 비밀번호를</p>
              <p className={P3SheetGreystyled.class}>
                다시 한 번 확인해 주세요
              </p>
              <div className={InModalGreyBorderLine.class}></div>
              <button
                className={btnModalGrey.class}
                onClick={modalConfirmHandler}
              >
                확인
              </button>
            </ModalInteraction>
          </div>
        )}
        <div className="mt-auto"></div>

        {isLoading ? (
          <motion.button
            className={btnMain.class}
            animate={{ opacity: isLoading ? 0.7 : 1 }}
          >
            <Lottie
              animationData={LottieLoading}
              loop={true}
              className={btnLoading.class}
            />
          </motion.button>
        ) : (
          <BasicButton onClick={confirmAction} disabled={confirmBtnDisabled}>
            확인
          </BasicButton>
        )}
      </BasicBox>
    </div>
  );
};

export default PasswordChangeSettingInput;
