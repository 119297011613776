// 확인 버튼 훅
export const useConfirm = (
  message = "",
  onConfirm: () => void,
  onCancel: () => void
) => {
  if (!onConfirm || typeof onConfirm !== "function") {
    return;
  }
  if (!onCancel || typeof onCancel !== "function") {
    return;
  }

  const confirmAction = () => {
    if (message === "") {
      onConfirm();
    } else {
      if (window.confirm(message)) {
        onConfirm();
      } else {
        onCancel();
      }
    }
  };

  return confirmAction;
};
