export const OPENBANKING_REDIRECT_URL: string =
  process.env.REACT_APP_OPENBANKING_REDIRECT_URL || '';
export const MAIN_SERVER_URL: string =
  process.env.REACT_APP_MAIN_SERVER_URL || '';
export const POLLING_SERVER_URL: string =
  process.env.REACT_APP_POLLING_SERVER_URL || '';
export const OPENBANKING_OAUTH_BASEURL: string =
  process.env.REACT_APP_OPENBANKING_OAUTH_BASEURL || '';
export const OPENBANKING_CLIENT_ID: string =
  process.env.REACT_APP_OPENBANKING_CLIENT_ID || '';
