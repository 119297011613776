import { tw } from '../../utils/Tailwindest';



export const ModalContainerLayout = tw.style({
  position: 'absolute',
  display: 'flex',
  alignItems: 'items-center',
  justifyContent: 'justify-center',
  inset: 'inset-0',
  backgroundColor: 'bg-p3_bg_cloudy',
  backdropOpacity: 'backdrop-opacity-100',
  zIndex: 'z-40',
  borderRadius: 'rounded-3xl',
});
export const AlertContainerLayout = tw.style({
  position: 'absolute',
  inset: 'inset-0',
  display: 'flex',
  alignItems: 'items-center',
  justifyContent: 'justify-center',
  backgroundColor: 'bg-p3_bg_cloudy',
  zIndex: 'z-40',
});

export const ModalContainer = tw.style({
  backgroundColor: 'bg-p3_modal_background',
  position: "absolute",
  zIndex: 'z-50',
  width: 'w-[100%]',
  maxWidth: "max-w-[768px]",
  maxHeight: "max-h-[180px]",
  height: 'h-[35%]',
  bottom: "bottom-0",
  borderTopRadius: 'rounded-t-[50px]',
  textAlign: 'text-center',
  padding: 'p-3',
  borderRadius: 'rounded-xl',

  '@modalH': {
    maxHeight: "modalH:max-h-[250px]",
    height: "modalH:h-[26%]"
  },
});

export const AlertContainer = tw.style({
  backgroundColor: 'bg-p3_modal_background',
  zIndex: 'z-50',
  width: 'w-[80%]',
  height: 'h-[24%]',
  maxHeight: "max-h-[260px]",
  maxWidth: "max-w-[400px]",
  minHeight: "min-h-[170px]",
  top: 'top-auto',
  borderRadius: 'rounded-xl',
  textAlign: 'text-center',

  '@tabletH': {
    height: "tabletH:h-[28%]"
  },


});

export const InModalContainer = tw.style({
  marginTop: 'mt-[55px]',
});
