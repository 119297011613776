import { motion } from 'framer-motion';
import React, { forwardRef } from 'react';
import { ModalContainer } from '../../styles/modal/InfoModal.styled';

interface ModalVariantsprops {
  children: React.ReactNode;
  ModalVariants: any;
}

const ModalInteraction = forwardRef<HTMLDivElement, ModalVariantsprops>(
  ({ children, ModalVariants }, ref) => {
    return (
      <motion.div
        className={`${ModalContainer.class} pointer-events-auto`}
        onClick={(e) => e.stopPropagation()}
        onTouchStart={(e) => e.stopPropagation()}
        variants={ModalVariants}
        initial="hidden"
        animate="visible"
        exit="hidden"
        ref={ref} // ref를 motion.div에 전달
      >
        {children}
      </motion.div>
    );
  },
);
ModalInteraction.displayName = 'ModalInteraction';
export default ModalInteraction;
