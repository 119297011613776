import axios, {
  AxiosError,
  AxiosInstance,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from 'axios';
import { encrypt } from './utils/encrypt';
import {
  AuthCodeSendDto,
  GetCodeDto,
  phoneAuthToken,
  SetCodeDto,
} from './interfaces/pollingAPI';
import { POLLING_SERVER_URL } from '../constants';

export class PollingAPI {
  private axios: AxiosInstance;
  private encryptAxios: AxiosInstance;

  private static instance: PollingAPI;

  constructor() {
    this.axios = axios.create({
      baseURL: POLLING_SERVER_URL,
    });

    this.axios.interceptors.request.use(this.onRequest);
    this.axios.interceptors.response.use(this.onResponse, this.onError);

    this.encryptAxios = axios.create({
      baseURL: POLLING_SERVER_URL,
    });

    this.encryptAxios.interceptors.request.use(this.onRequestEncrypt);
    this.encryptAxios.interceptors.response.use(this.onResponse, this.onError);

    PollingAPI.instance = this;
  }

  static getInstance() {
    if (!PollingAPI.instance) {
      PollingAPI.init();
    }

    return PollingAPI.instance;
  }

  static init() {
    if (PollingAPI.instance) {
      throw new Error('MainAPI is already initialized.');
    }

    PollingAPI.instance = new PollingAPI();
  }

  private onRequest = (config: InternalAxiosRequestConfig) => {
    const { method, url } = config;
    console.log(`[PollingAPI Request] ${method?.toUpperCase()} ${url}`);

    return config;
  };

  private onRequestEncrypt = (config: InternalAxiosRequestConfig) => {
    const { method, url } = config;
    console.log(`[PollingAPI Request] ${method?.toUpperCase()} ${url}`);

    const encrypted = encrypt(JSON.stringify(config.data));
    if (!encrypted) {
      throw new Error('Failed to encrypt request data');
    }

    const { ciphertext, key } = encrypted;
    config.headers['V-VIOLET-AUTH'] = key;
    config.headers['Content-Type'] = 'text/plain';
    config.data = ciphertext;

    return config;
  };

  private onResponse = (res: AxiosResponse) => {
    const { method, url } = res.config;
    console.log(
      `[PollingAPI Response] ${method?.toUpperCase()} ${url} response -> HTTPSTATUS: ${res.status}, ${res.data}`,
    );

    return res;
  };

  private onError = (error: AxiosError | Error) => {
    if (axios.isAxiosError(error)) {
      const { method, url } = error.config as InternalAxiosRequestConfig;

      console.log(
        `[PollingAPI Error] ${method?.toUpperCase()} ${url} failed -> HTTPSTATUS: ${error.response?.status}, ${error.response?.data}`,
      );
    } else {
      console.log(`[PollingAPI Error] ${error.message}`);
    }

    return Promise.reject(error);
  };

  static authCodeSend = async (
    phoneNumber: string,
  ): Promise<AxiosResponse<AuthCodeSendDto>> => {
    const instance = PollingAPI.getInstance();
    return await instance.encryptAxios.post('/authcode/send', {
      phoneNumber,
    });
  };

  static authCodeVerify = async (
    phoneNumber: string,
    authCode: string,
  ): Promise<AxiosResponse<phoneAuthToken>> => {
    const instance = PollingAPI.getInstance();
    return await instance.encryptAxios.post('/authcode/verify', {
      phoneNumber,
      authCode,
    });
  };

  static getCode = async (
    sessid: string,
  ): Promise<AxiosResponse<GetCodeDto>> => {
    const instance = PollingAPI.getInstance();
    return await instance.axios.get('/code', { params: { sessid } });
  };

  static setCode = async (
    sessid: string,
    code: string,
  ): Promise<AxiosResponse<SetCodeDto>> => {
    const instance = PollingAPI.getInstance();
    return await instance.encryptAxios.post('/code', { sessid, code });
  };
}
