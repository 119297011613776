import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface p3Auth {
  p3_clearance: string;
  p3_payload: string;
}

const initialState: p3Auth = {
  p3_clearance: '',
  p3_payload: '',
};

export const p3AuthSlice = createSlice({
  name: 'p3Auth',
  initialState,
  reducers: {
    setP3Auth: (state, action: PayloadAction<p3Auth>) => {
      state.p3_clearance = action.payload.p3_clearance;
      state.p3_payload = action.payload.p3_payload;
    },
  },
});

export const { setP3Auth } = p3AuthSlice.actions;

export default p3AuthSlice.reducer;
