import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useModal } from '../../../../hooks/useModal.hook';
import { useConfirm } from '../../../../hooks/useConfirm.hook';
import BasicBox from '../../../../components/box/basicBox/BasicBox';
import BackButton from '../../../../components/button/BackButton';
import PWPageInteraction from '../../../../components/Interection/PWSettingPageInteraction';
import {
  P3AlertTextWhite,
  P3AlertTextgrey,
  P3GreyTextStyled,
  P3WhiteTextStyled,
  P3WhiteTextStyled_mb,
} from '../../../../styles/text/P3Text.styled';
import CustomPinInput from '../../../../contents/pin';
import { AlertContainerLayout } from '../../../../styles/modal/InfoModal.styled';
import AlertInteraction from '../../../../components/Interection/AlertInteraction';
import { InAlertGreyBorderLine } from '../../../../styles/line/BorderLine.styled';
import { btnModalGrey } from '../../../../styles/btn/Btn.styled';
import BasicButton from '../../../../components/button/ConfirmButton';

const PasswordChangeSetting = () => {
  const navigate = useNavigate();
  const [pinsave, setPinsave] = useState<string>('');
  const [validateFailedMsg, setValidateFailedMsg] = useState<string>('');
  const [confirmBtnDisabled, setConfirmBtnDisabled] = useState<boolean>(true);
  const modalRef = useRef<HTMLDivElement>(null);
  const [cleanFlag, setCleanFlag] = useState<boolean>(false);
  const { modalIsOpen, openModal, closeModal } = useModal();

  const goToPrevious = () => {
    navigate('/user/mypage');
  };

  const goToNext = () => {
    navigate('/password/change/setting_input', {
      state: {
        SettingPassword: pinsave,
      },
    });
  };

  // 비밀번호 유효성 검사
  const validatePassword = (password: string) => {
    const errors = [];

    if (password.length !== 4) {
      errors.push('비밀번호는 4자리 숫자여야 합니다.|');
    }

    if (!/^\d{4}$/.test(password)) {
      errors.push('비밀번호는 숫자만 포함할 수 있습니다.|');
    }

    if (/(?:.*(\d)(?:.*?\1){2})/.test(password)) {
      errors.push('비밀번호에 3개 이상 중복된 숫자를 | 사용할 수 없습니다.');
    }

    if (
      /1234|2345|3456|4567|5678|6789|9876|8765|7654|6543|5432|4321/.test(
        password,
      )
    ) {
      errors.push('비밀번호에 연속된 숫자를 | 사용할 수 없습니다.');
    }

    const successed = errors.length === 0;
    const failed = errors;
    return { successed, failed };
  };

  const onConfirmClick = () => {
    const res = validatePassword(pinsave);
    console.log('유효성검사', res);
    if (res.failed.length > 0) {
      setValidateFailedMsg(res.failed.join('\n'));
      openModal();
    }
    if (res.successed === true) {
      goToNext();
    }
  };

  const pinSaveHandler = (value: string) => {
    setPinsave(value);
    console.log(value);
    if (value.length >= 4) {
      setConfirmBtnDisabled(false);
    } else {
      setConfirmBtnDisabled(true);
    }
  };

  const inModalConfirmBtn = () => {
    setCleanFlag((current) => !current);
    setConfirmBtnDisabled(true);
    closeModal();
  };

  const confirmAction = useConfirm('', onConfirmClick, goToPrevious);

  return (
    <div className="flex items-center justify-center h-[100dvh] w-screen">
      <BasicBox>
        <BackButton show={true} onClick={goToPrevious} />
        <PWPageInteraction
          PageTransition={{
            initial: { opacity: 0.5 },
            animate: {
              opacity: 1,
              transition: {
                ease: [0.6, 0.9, 0.6, 0.5],
              },
            },
          }}
        >
          <div className={`${P3WhiteTextStyled_mb.class} flex align-start`}>
            비밀번호 설정
          </div>
          <div className={P3GreyTextStyled.class}>
            변경 할 비밀번호를 설정합니다.
          </div>

          <CustomPinInput
            length={4}
            onPinsave={pinSaveHandler}
            onClean={cleanFlag}
          />

          {modalIsOpen && (
            <div
              className={`${AlertContainerLayout.class} pointer-events-auto`}
              onClick={(e) => e.stopPropagation()}
              onTouchStart={(e) => e.stopPropagation()}
            >
              <AlertInteraction
                ref={modalRef}
                AlertVariants={{
                  hidden: {
                    scale: 0.75,
                    opacity: 0,
                  },
                  visible: {
                    scale: 1,
                    opacity: 1,
                    transition: {
                      duration: 0.2,
                      ease: 'easeInOut',
                    },
                  },
                }}
              >
                <h3 className={P3AlertTextWhite.class}>비밀번호 오류</h3>
                <p className={P3AlertTextgrey.class}>
                  {validateFailedMsg.split('|').map((msg, index, array) => (
                    <React.Fragment key={index}>
                      {msg}
                      {index < array.length - 1 && <br />}
                    </React.Fragment>
                  ))}
                </p>
                <div className={InAlertGreyBorderLine.class}></div>
                <button
                  className={btnModalGrey.class}
                  onClick={inModalConfirmBtn}
                >
                  확인
                </button>
              </AlertInteraction>
            </div>
          )}
        </PWPageInteraction>
        <div className="mt-auto"></div>
        <BasicButton onClick={confirmAction} disabled={confirmBtnDisabled}>
          설정하기
        </BasicButton>
      </BasicBox>
    </div>
  );
};

export default PasswordChangeSetting;
