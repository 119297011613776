import React, { useEffect, useRef, useState } from 'react';
import BasicBox from '../../../components/box/basicBox/BasicBox';
import BackButton from '../../../components/button/BackButton';
import BasicButton from '../../../components/button/ConfirmButton';
import { useLocation, useNavigate } from 'react-router-dom';
import { useConfirm } from '../../../hooks/useConfirm.hook';
import {
  P3AlertTextWhite,
  P3AlertTextgrey,
  P3GreyTextStyled,
  P3WhiteTextStyled_mb,
} from '../../../styles/text/P3Text.styled';
import PWPageInteraction from '../../../components/Interection/PWSettingPageInteraction';
import CustomPinInput from '../../../contents/pin';
import { useModal } from '../../../hooks/useModal.hook';
import { InAlertGreyBorderLine } from '../../../styles/line/BorderLine.styled';
import { btnModalGrey } from '../../../styles/btn/Btn.styled';
import { AlertContainerLayout } from '../../../styles/modal/InfoModal.styled';
import AlertInteraction from '../../../components/Interection/AlertInteraction';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';

const PasswordSetting = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { PollingCode, PhoneAuthedCode } = location.state || {};
  const [pinsave, setPinsave] = useState<string>('');
  const [validateFailedMsg, setValidateFailedMsg] = useState<string>('');
  const [confirmBtnDisabled, setConfirmBtnDisabled] = useState<boolean>(true);
  const modalRef = useRef<HTMLDivElement>(null);
  const [cleanFlag, setCleanFlag] = useState<boolean>(false);
  const { modalIsOpen, openModal, closeModal } = useModal();

  const PurchaseToken = useSelector(
    (state: RootState) => state.purchaseToken.purchaseToken,
  );

  const pinSaveHandler = (value: string) => {
    setPinsave(value);
    console.log(value);
    if (value.length >= 4) {
      setConfirmBtnDisabled(false);
    } else {
      setConfirmBtnDisabled(true);
    }
  };

  const goToNext = () => {
    navigate('/password/setting_input', {
      state: {
        PollingCode: PollingCode,
        PhoneAuthedCode: PhoneAuthedCode,
        FirstPinsave: pinsave,
      },
    });
  };

  const goToPrevious = () => {
    navigate('/password/setting', {
      state: {
        PollingCode: PollingCode,
        PhoneAuthedCode: PhoneAuthedCode,
      },
    });
  };

  useEffect(() => {
    console.log('PollingCode:', PollingCode);
    console.log('PurchaseToken:', PurchaseToken);
    console.log('PhoneAuthedCode:', PhoneAuthedCode);
  }, [PollingCode, PurchaseToken, PhoneAuthedCode]);

  // 비밀번호 유효성 검사
  const validatePassword = (password: string) => {
    const errors = [];

    if (password.length !== 4) {
      errors.push('비밀번호는 4자리 숫자여야 합니다.|');
    }

    if (!/^\d{4}$/.test(password)) {
      errors.push('비밀번호는 숫자만 포함할 수 있습니다.|');
    }

    if (/(?:.*(\d)(?:.*?\1){2})/.test(password)) {
      errors.push('비밀번호에 3개 이상 중복된 숫자를 | 사용할 수 없습니다.');
    }

    if (
      /1234|2345|3456|4567|5678|6789|9876|8765|7654|6543|5432|4321/.test(
        password,
      )
    ) {
      errors.push('비밀번호에 연속된 숫자를 | 사용할 수 없습니다.');
    }

    const successed = errors.length === 0;
    const failed = errors;
    return { successed, failed };
  };

  const onConfirmClick = () => {
    const res = validatePassword(pinsave);
    console.log('유효성검사', res);
    if (res.failed.length > 0) {
      setValidateFailedMsg(res.failed.join('\n'));
      openModal();
      goToPrevious();
    }
    if (res.successed === true) {
      goToNext();
    }
  };

  const inModalConfirmBtn = () => {
    closeModal();
    setCleanFlag((current) => !current);
  };

  const confirmAction = useConfirm('', onConfirmClick, goToPrevious);

  return (
    <div className="flex items-center justify-center h-[100dvh] w-screen">
      <BasicBox>
        <BackButton show={false} />
        <PWPageInteraction
          PageTransition={{
            initial: { opacity: 0.5 },
            animate: {
              opacity: 1,
              transition: {
                ease: [0.6, 0.9, 0.6, 0.5],
              },
            },
          }}
        >


          <div className={P3WhiteTextStyled_mb.class}>간편 비밀번호 설정</div>
          <div className={P3GreyTextStyled.class}>
            결제시 사용할 비밀번호를 설정합니다.
          </div>



          <CustomPinInput
            length={4}
            onPinsave={pinSaveHandler}
            onClean={cleanFlag}
          />


        </PWPageInteraction>

        {modalIsOpen && (
          <div
            className={`${AlertContainerLayout.class} pointer-events-auto`}
            onClick={(e) => e.stopPropagation()}
            onTouchStart={(e) => e.stopPropagation()}
          >
            <AlertInteraction
              ref={modalRef}
              AlertVariants={{
                hidden: {
                  scale: 0.75,
                  opacity: 0,
                },
                visible: {
                  scale: 1,
                  opacity: 1,
                  transition: {
                    duration: 0.2,
                    ease: 'easeInOut',
                  },
                },
              }}
            >
              <h3 className={P3AlertTextWhite.class}>비밀번호 오류</h3>
              <p className={P3AlertTextgrey.class}>
                {validateFailedMsg.split('|').map((msg, index, array) => (
                  <React.Fragment key={index}>
                    {msg}
                    {index < array.length - 1 && <br />}
                  </React.Fragment>
                ))}
              </p>
              <div className={InAlertGreyBorderLine.class}></div>
              <button
                className={btnModalGrey.class}
                onClick={inModalConfirmBtn}
              >
                확인
              </button>
            </AlertInteraction>
          </div>
        )}

        <div className="mt-auto"></div>
        <BasicButton onClick={confirmAction} disabled={confirmBtnDisabled}>
          설정하기
        </BasicButton>
      </BasicBox>
    </div>
  );
};

export default PasswordSetting;
