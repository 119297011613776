import React from 'react';
import { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useConfirm } from '../../hooks/useConfirm.hook';
import BasicBox from '../../components/box/basicBox/BasicBox';
import BackButton from '../../components/button/BackButton';
import BasicButton from '../../components/button/ConfirmButton';
import {
  GreyBorderLine,
  RedBorderLine,
} from '../../styles/line/BorderLine.styled';
import {
  P3WhiteTextStyled,
  P3AlertTextWhite,
  P3AlertTextgrey,
  P3GreyLabelStyled,
  P3GreyInputStyled
} from '../../styles/text/P3Text.styled';
import { AlertContainerLayout } from '../../styles/modal/InfoModal.styled';
import AlertInteraction from '../../components/Interection/AlertInteraction';
import { btnModalGrey } from '../../styles/btn/Btn.styled';
import { InAlertGreyBorderLine } from '../../styles/line/BorderLine.styled';
import PageInteraction from '../../components/Interection/PageInteraction';
import { PollingAPI } from '../../api/pollingAPI';
import { useModal } from '../../hooks/useModal.hook';
import { useOutsideClick } from '../../hooks/useOutsideClick.hook';
import axios, { AxiosError } from 'axios';
import { RootState } from '../../store/store';
import { useSelector } from 'react-redux';

const PhoneAuthMain = () => {
  const navigate = useNavigate();
  const modalRef = useRef<HTMLDivElement>(null);
  const [numberInput, setNumberInput] = useState<string>('');
  const [overMsg, setOverMsg] = useState<boolean>(false);
  const { modalIsOpen, openModal, closeModal } = useModal();
  const [confirmBtnDisabled, setConfirmBtnDisabled] = useState<boolean>(false);
  useOutsideClick(modalRef, closeModal, modalIsOpen);


  const PurchaseToken = useSelector(
    (state: RootState) => state.purchaseToken.purchaseToken,
  );

  const goToPrevious = () => navigate(-1);
  const handleBackClick = () => {
    navigate('/', { replace: true });
  };
  useEffect(() => {
    console.log('PurchaseToken:', PurchaseToken);
  }, [PurchaseToken]);

  // 문자 인증
  const sendPhoneNumberAuthReq = async () => {
    const formettedPhoneNumber = numberInput.replace(/-/g, '');
    if (formettedPhoneNumber.length !== 11) {
      openModal();
      return;
    }

    try {
      const res = await PollingAPI.authCodeSend(formettedPhoneNumber);
      console.log(res);
      navigate('/phoneAuth/confirm', {
        state: {
          PhoneNumber: numberInput,
          PurchaseToken: PurchaseToken,
        },
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const axiosError = error as AxiosError;
        console.log(axiosError);
        // 하루 전송량 초과 에러
        if (axiosError.response?.status === 429) {
          setOverMsg(true);
          openModal();
          return;
        }
      }
    }
    setConfirmBtnDisabled(true);
  };

  const confirmAction = useConfirm('', sendPhoneNumberAuthReq, goToPrevious);

  const inputChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    // 숫자 말고는 타이핑 할 수 없게 하는 정규식
    const onlyNums = value.replace(/[^\d]/g, '');

    if (onlyNums.length <= 3) {
      setNumberInput(onlyNums);
    } else if (onlyNums.length <= 7) {
      setNumberInput(`${onlyNums.slice(0, 3)}-${onlyNums.slice(3)}`);
    } else if (onlyNums.length <= 11) {
      setNumberInput(
        `${onlyNums.slice(0, 3)}-${onlyNums.slice(3, 7)}-${onlyNums.slice(7)}`,
      );
    }
  };

  return (
    <div className="flex justify-center items-center w-screen h-[100vh]">
      <BasicBox>
        <BackButton show={true} onClick={handleBackClick} />
        <PageInteraction
          PageTransition={{
            initial: { opacity: 0.5 },
            animate: {
              opacity: 1,
              transition: {
                ease: [0.6, 0.9, 0.6, 0.5],
              },
            },
          }}
        >
          {modalIsOpen && (
            <div
              className={`${AlertContainerLayout.class} pointer-events-auto`}
              onClick={(e) => e.stopPropagation()}
              onTouchStart={(e) => e.stopPropagation()}
            >
              <AlertInteraction
                ref={modalRef}
                AlertVariants={{
                  hidden: {
                    scale: 0.75,
                    opacity: 0,
                  },
                  visible: {
                    scale: 1,
                    opacity: 1,
                    transition: {
                      duration: 0.2,
                      ease: 'easeInOut',
                    },
                  },
                }}
              >
                {overMsg ? (
                  <React.Fragment>
                    <h3 className={P3AlertTextWhite.class}>연속적인 메세지</h3>
                    <p className={P3AlertTextgrey.class}>
                      과도한 요청은 서버를 아프게 해요
                    </p>
                    <p className={P3AlertTextgrey.class}>
                      메세지는 1번씩만 요청해 주세요
                    </p>
                    <div className={InAlertGreyBorderLine.class}></div>
                    <button className={btnModalGrey.class} onClick={closeModal}>
                      확인
                    </button>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <h3 className={P3AlertTextWhite.class}>
                      휴대폰 번호가 일치하지 않아요
                    </h3>
                    <p className={P3AlertTextgrey.class}>
                      입력하신 휴대폰 번호를
                    </p>
                    <p className={P3AlertTextgrey.class}>
                      다시 한 번 확인해 주세요
                    </p>
                    <div className={InAlertGreyBorderLine.class}></div>
                    <button className={btnModalGrey.class} onClick={closeModal}>
                      확인
                    </button>
                  </React.Fragment>
                )}
              </AlertInteraction>
            </div>
          )}

          <div className={P3WhiteTextStyled.class}>휴대폰 인증</div>

          <label
            className={P3GreyLabelStyled.class}
          >
            휴대폰 번호
          </label>
          <input
            type="tel"
            pattern="\d*"
            placeholder="번호 입력"
            value={numberInput}
            onChange={inputChangeHandler}
            className={`${numberInput.length === 13
              ? RedBorderLine.class
              : GreyBorderLine.class
              }  placeholder-p3_placeholder ${P3GreyInputStyled.class}`}
          />

        </PageInteraction>

        <div className='mt-auto'></div>

        <BasicButton onClick={confirmAction} disabled={confirmBtnDisabled}>
          확인하기
        </BasicButton>
      </BasicBox>
    </div>
  );
};

export default PhoneAuthMain;
