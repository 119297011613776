import { motion } from 'framer-motion';
import React from 'react';
import { PWInteraction } from '../../styles/password/PWPage.styled';

interface PWPagePageInteractionProps {
  children: React.ReactNode;
  PageTransition: any;
}

const PWPageInteraction: React.FC<PWPagePageInteractionProps> = ({
  children,
  PageTransition,
}) => {
  return (
    <motion.div
      className={PWInteraction.class}
      initial="initial"
      animate="animate"
      exit="exit"
      variants={PageTransition}
    >
      {children}
    </motion.div>
  );
};

export default PWPageInteraction;
