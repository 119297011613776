import axios, { AxiosError } from 'axios';
import { NavigateFunction } from 'react-router-dom';
import { IServerError } from '../../interface/serverError.interface';
import { SendMessage } from '../iframe';

// interface axiosErrorHandlerProps {
//   error: IServerError;
//   navigate: NavigateFunction;
//   resData?: any;
// }

export const mainAxiosErrorHandler = (
  error: unknown,
  navigate: NavigateFunction,
  resData?: string,
) => {
  if (axios.isAxiosError<IServerError>(error)) {
    const axiosError = error as AxiosError<IServerError>;
    if (axiosError.response) {
      const serverError: IServerError = axiosError.response.data;
      console.log(serverError);

      switch (serverError.statusCode) {
        // 사용자를 찾을 수 없는 경우
        case 1013:
          navigate('/phoneAuth/main');
          break;
        // 클라이언트 정보로 사용자를 찾을 수 없는 경우
        case 1051:
          navigate('/phoneAuth/main');
          break;
        // 비밀번호 5회 틀린 유저가 로그인 한 경우
        case 3003:
          navigate('/password/error');
          break;
        // 알 수 없는 에러인 경우 => 주로 PurchaseToken이 없는 경우
        case 5000:
          SendMessage('error', null);
          break;
        default:
          console.log('Unhandled server error:', serverError);
          return;
      }
    }
  }
};
