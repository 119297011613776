import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
  totalCharge: 0,
};

export const totalChargeSlice = createSlice({
  name: "totalCharge",
  initialState,
  reducers: {
    setTotalCharge: (state, action: PayloadAction<number>) => {
      state.totalCharge = action.payload;
    },
  },
});

export const { setTotalCharge } = totalChargeSlice.actions;

export default totalChargeSlice.reducer;
