import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import axios, { AxiosError } from 'axios';
import { useModal } from '../../../../hooks/useModal.hook';
import { useSelector } from 'react-redux';
import { useOutsideClick } from '../../../../hooks/useOutsideClick.hook';
import { ModalContainerLayout } from '../../../../styles/modal/InfoModal.styled';
import ModalInteraction from '../../../../components/Interection/ModalInteraction';
import {
  P3GreyTextStyled,
  P3WhiteTextStyled,
  P3WhiteTextStyled_2,
} from '../../../../styles/text/P3Text.styled';
import { InModalGreyBorderLine } from '../../../../styles/line/BorderLine.styled';
import { btnModalGrey } from '../../../../styles/btn/Btn.styled';
import { MainAPI } from '../../../../api/mainAPI';
import { useConfirm } from '../../../../hooks/useConfirm.hook';
import BasicBox from '../../../../components/box/basicBox/BasicBox';
import BackButton from '../../../../components/button/BackButton';
import PWPageInteraction from '../../../../components/Interection/PWSettingPageInteraction';
import CustomPinInput from '../../../../contents/pin';
import BasicButton from '../../../../components/button/ConfirmButton';
import { RootState } from '../../../../store/store';
import { IServerError } from '../../../../interface/serverError.interface';

const PasswordChange = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const pinInputRef = useRef<HTMLInputElement>(null);
  const modalRef = useRef<HTMLDivElement>(null);

  const [pinsave, setPinSave] = useState<string>('');

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isInputDisabled, setIsInputDisabled] = useState<boolean>(false); // 숫자패드 안눌리게
  const [confirmBtnDisabled, setConfirmBtnDisabled] = useState<boolean>(true); // 확인 버튼 안눌리게
  const [cleanFlag, setCleanFlag] = useState<boolean>(false); // 적혀있던 숫자패드 클리어
  const { modalIsOpen, openModal, closeModal } = useModal();
  const [showBackButton, setShowBackButton] = useState<boolean>(true); // 뒤로가기 숨기기

  const { SettingPassword } = location.state || {};

  const p3Auth = useSelector((state: RootState) => state.p3Auth);

  const closeModalHandler = () => {
    setCleanFlag((current) => !current);
    setConfirmBtnDisabled(true);
    closeModal();
  };

  useOutsideClick(modalRef, closeModalHandler, modalIsOpen);

  const goToPrevious = () =>
    navigate('/password/change/setting');

  useEffect(() => {
    if (pinInputRef.current) {
      pinInputRef.current?.focus();
    }
  }, [pinInputRef]);

  const completeHandler = (value: string) => {
    setPinSave(value);
    if (value.length >= 4) {
      setConfirmBtnDisabled(false);
    } else {
      setConfirmBtnDisabled(true);
    }
  };

  const handleSummit = async () => {
    setIsLoading(true);
    // setShowBackButton(false);

    try {
      const res = await MainAPI.passwordUpdate(
        pinsave,
        SettingPassword,
        p3Auth,
      );

      if (res.data.statusCode === 200 || res.data.statusCode === 201) {
        setIsInputDisabled(true);
        navigate('/password/change/success');
      }
    } catch (error) {
      if (axios.isAxiosError<IServerError>(error)) {
        const axiosError = error as AxiosError<IServerError>;
        if (axiosError.response) {
          const serverError: IServerError = axiosError.response.data;
          console.log(serverError);
          if (serverError.statusCode === 3000) {
            openModal();
          }
        }
      }
      console.log(error);
    }
  };

  const modalConfirmHandler = () => {
    closeModal();
    setCleanFlag((current) => !current);
    setConfirmBtnDisabled(true);
  };

  const confirmAction = useConfirm('', handleSummit, goToPrevious);

  return (
    <div className="flex items-center justify-center h-[100dvh]w-screen">
      <BasicBox>
        <BackButton show={showBackButton} onClick={goToPrevious} />
        <PWPageInteraction
          PageTransition={{
            initial: { opacity: 0.5 },
            animate: {
              opacity: 1,
              transition: {
                ease: [0.6, 0.9, 0.6, 0.5],
              },
            },
          }}
        >
          <div className={P3WhiteTextStyled.class}>기존 비밀번호 입력</div>
          <div className={P3GreyTextStyled.class}>
            기존에 설정된 비밀번호를 입력해 주세요.
          </div>

          <CustomPinInput
            length={4}
            onClean={cleanFlag}
            onPinsave={completeHandler}
            isInputDisabled={isInputDisabled}
          />
        </PWPageInteraction>
        {modalIsOpen && (
          <div
            className={`${ModalContainerLayout.class} pointer-events-auto`}
            onClick={(e) => e.stopPropagation()}
            onTouchStart={(e) => e.stopPropagation()}
          >
            <ModalInteraction
              ref={modalRef}
              ModalVariants={{
                hidden: {
                  y: '100vh',
                  opacity: 0,
                },
                visible: {
                  y: 0,
                  opacity: 1,
                  transition: { type: 'spring', stiffness: 250, damping: 30 },
                },
              }}
            >
              <br />
              <h2 className={P3WhiteTextStyled_2.class}>
                비밀번호 5회 오류 시
              </h2>
              <br />
              <h2 className={P3WhiteTextStyled_2.class}>
                보안을 위해 결제가 제한됩니다
              </h2>
              <br />
              <div className={InModalGreyBorderLine.class}></div>
              <button
                className={btnModalGrey.class}
                onClick={modalConfirmHandler}
              >
                확인
              </button>
            </ModalInteraction>
          </div>
        )}
        <div className="mt-auto"></div>
        <BasicButton onClick={confirmAction} disabled={confirmBtnDisabled}>
          확인
        </BasicButton>
      </BasicBox>
    </div>
  );
};

export default PasswordChange;
