import { tw } from '../../utils/Tailwindest';

export const P3GreyTextStyled = tw.style({
  fontSize: "text-[1rem]",
  color: 'text-p3_grey',
  marginTop: 'mt-[1.25rem]',
  display: 'flex',
  flexDirection: 'flex-col',
  fontWeight: 'font-bold',
  '@tabletH': {
    fontSize: "tabletH:text-[clamp(1rem,3.25vw,2rem)]",
    marginTop: 'tabletH:mt-[clamp(1.25rem,4vw,2rem)]',
  },
});

export const P3GreyTextStyled_mt = tw.style({
  fontSize: "text-[1rem]",
  color: 'text-p3_grey',
  marginTop: 'mt-[0.75rem]',
  fontWeight: 'font-bold',
  '@tabletH': {
    fontSize: "tabletH:text-[clamp(1rem,3.25vw,2rem)]",
    marginTop: 'tabletH:mt-[clamp(0.75rem,2vw,2rem)]',
  },
});

export const P3GreyTextStyled_2 = tw.style({
  fontSize: "text-[1.5rem]",
  color: 'text-p3_grey',
  fontWeight: 'font-thin',
  marginBottom: 'mb-2',
  '@tabletH': {
    fontSize: "tabletH:text-[clamp(1.5rem,5vw,2.5rem)]",
  },
});

export const P3GreyTextStyled_3 = tw.style({
  fontSize: "text-[1rem]",
  color: 'text-p3_grey',
  fontWeight: 'font-bold',
  marginBottom: 'mb-2',
  marginTop: 'mt-[1.75rem]',
  '@tabletH': {
    fontSize: "tabletH:text-[clamp(1rem,3.25vw,2rem)]",
    marginTop: 'tabletH:mt-[clamp(1.75rem,5vw,3rem)]',
  },
});

export const P3GreyTextStyled_4 = tw.style({
  fontSize: 'text-[1.25rem]',
  color: 'text-p3_grey',
  fontWeight: 'font-thin',
  display: 'flex',
  flexDirection: "flex-col",
  '@tabletH': {
    fontSize: "tabletH:text-[clamp(1.25rem,4vw,1.875rem)]",
  },
});

export const P3WhiteTextStyled = tw.style({
  fontSize: "text-[1.5rem]",
  marginTop: 'mt-[1.75rem]',
  marginBottom: "mb-[0.125rem]",
  fontWeight: 'font-bold',
  '@tabletH': {
    fontSize: "tabletH:text-[clamp(1.5rem,5vw,2.75rem)]",
    marginTop: 'tabletH:mt-[clamp(1.75rem,5vw,3rem)]',
    marginBottom: "tabletH:mb-[clamp(0.125rem,3vw,5rem)]"
  },
});

export const P3WhiteTextStyled_mb = tw.style({
  fontSize: "text-[1.5rem]",
  marginTop: 'mt-[1.75rem]',
  fontWeight: 'font-bold',
  '@tabletH': {
    fontSize: "tabletH:text-[clamp(1.5rem,5vw,3rem)]",
    marginTop: 'tabletH:mt-[clamp(1.75rem,5vw,3rem)]',
  },
});

export const P3WhiteTextStyled_2 = tw.style({
  fontSize: "text-[1.25rem]",
  color: 'text-p3_white',
  '@tabletH': {
    fontSize: "tabletH:text-[clamp(1.25rem,3vw,2.5rem)]",
  },
});

export const P3GreyLabelStyled = tw.style({
  display: "flex",
  alignItems: "items-end",
  height: "h-[2.5rem]",
  color: 'text-p3_grey',
  fontSize: "text-[0.875rem]",
  fontWeight: 'font-bold',
  '@tabletH': {
    fontSize: "tabletH:text-[clamp(0.875rem,2.75vw,1.725rem)]",
    height: "tabletH:h-[clamp(2.5rem,6vw,4rem)]"
  },
});

export const P3GreyLabelStyled_2 = tw.style({
  color: 'text-p3_grey',
  fontSize: "text-[0.875rem]",
  fontWeight: 'font-bold',
  '@tabletH': {
    fontSize: "tabletH:text-[clamp(0.875rem,2.75vw,1.725rem)]",
  },
});

export const P3GreyInputStyled = tw.style({
  fontSize: "text-[1.5rem]",
  backgroundColor: 'bg-transparent',
  height: "h-[2.25rem]",
  fontWeight: "font-thin",
  outlineStyle: "outline-none",
  '@tabletH': {
    fontSize: "tabletH:text-[clamp(1.5rem,5vw,2.25rem)]",
    height: "tabletH:h-[clamp(2.5rem,7vw,4rem)]"
  },
});


export const P3AlertTextWhite = tw.style({
  fontSize: 'text-[1rem]',
  marginY: 'my-[1rem]',
  color: 'text-p3_white',
  '@tabletH': {
    fontSize: "tabletH:text-[clamp(1rem,3vw,1.5rem)]",
    marginY: "tabletH:my-[clamp(1rem,3vw,1.5rem)]"
  },
});

export const P3AlertTextgrey = tw.style({
  fontSize: 'text-[0.875rem]',
  color: 'text-p3_grey',
  marginY: 'my-1',
  display: 'flex',
  flexDirection: 'flex-col',
  '@tabletH': {
    fontSize: "tabletH:text-[clamp(0.875rem,3vw,1.5rem)]",
  },
});

export const P3SheetwhiteStyled = tw.style({
  fontSize: 'text-[1rem]',
  marginTop: 'mt-[1rem]',
  marginBottom: 'mb-[1rem]',
  color: 'text-p3_white',
  fontWeight: "font-bold",
  '@tabletH': {
    fontSize: "tabletH:text-[clamp(1rem,3.5vw,1.5rem)]",
    marginTop: "tabletH:mt-[clamp(1rem,3vw,1.5rem)]",
    marginBottom: "tabletH:mb-[clamp(1rem,3vw,1.5rem)]"
  },
});

export const P3SheetwhiteStyled_2 = tw.style({
  fontSize: 'text-[1.25rem]',
  marginTop: 'mt-[0.75rem]',
  marginBottom: 'mb-[1rem]',
  color: 'text-p3_white',
  '@tabletH': {
    fontSize: "tabletH:text-[clamp(1.25rem,3.5vw,2.5rem)]",
    marginTop: "tabletH:mt-[clamp(0.75rem,3vw,1.375rem)]",
    marginBottom: "tabletH:mb-[clamp(1rem,3vw,1.75rem)]"
  },
});

export const P3SheetGreystyled = tw.style({
  fontSize: 'text-[1rem]',
  color: 'text-p3_grey',
  display: 'flex',
  flexDirection: 'flex-col',
  '@tabletH': {
    fontSize: "tabletH:text-[clamp(1rem,3vw,1.5rem)]",
  },
});

export const P3GreyMypageInfoStyled = tw.style({
  color: 'text-p3_grey',
  fontSize: 'text-[0.875rem]',
  marginTop: "mt-[0.75rem]",
  '@tabletH': {
    fontSize: "tabletH:text-[clamp(0.875rem,3vw,1.5rem)]",
    marginTop: "tabletH:mt-[clamp(0.75rem,3vw,1.375rem)]",
  },
});

export const P3StatusTextStyled = tw.style({
  fontSize: 'text-[1rem]',

  '@desktopH': {
    fontSize: "desktopH:text-[clamp(1rem,3vw,1.625rem)]",
  },
});