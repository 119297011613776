import { createTools, type Tailwindest } from 'tailwindest';

type TailwindCustom = Tailwindest<{
  color:
  | 'p3_key_color' // 키 컬러 (확인하기 버튼 배경)
  | 'p3_background' // 기본 전체 배경(블랙)
  | 'p3_white' // 기본 텍스트 컬러(화이트)
  | 'p3_grey' // 회색 텍스트 컬러
  | 'p3_transparency' // 투명색
  | 'p3_back_btn' // 뒤로가기 버튼
  | 'p3_barely' // 거의 안보이는 색
  | 'p3_bg_cloudy' // 배경 흐리게
  | 'p3_padNum' // 숫자패드 색
  | 'p3_modal_background' // 모달 배경색
  | 'p3_modal_greyLine' // 모달창 내 회색 선
  | 'p3_modal_grey' // 모달창 내 회색 확인 버튼 컬러
  | 'p3_grey_btn'; // 비번 5회 틀렸을 시 잠긴 버튼

  screens: 'phone' | 'tablet' | 'desktop' | 'tabletH' | 'modalH' | 'modalHMAX' | 'desktopH';
}>;

const tw = createTools<TailwindCustom>();

export { tw };
