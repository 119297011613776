import { tw } from '../../utils/Tailwindest';

export const btnLoading = tw.style({
    height: "h-[2rem]",
    width: "w-[2rem]",
    '@tabletH': {
        width: "tabletH:w-[clamp(2rem,8vw,5rem)]",
        height: "tabletH:h-[clamp(2rem,8vw,5rem)]"
    }
});

export const inputLoading = tw.style({
    height: "h-[2.25rem]",
    width: "w-[2.25rem]",
    '@tabletH': {
        width: "tabletH:w-[clamp(2.25rem,7vw,4rem)]",
        height: "tabletH:h-[clamp(2.25rem,7vw,4rem)]"
    }
});
