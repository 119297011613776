import { useState } from "react";
import { IUseModal } from "../interface/modal.interface";

export const useModal = (initialState = false): IUseModal => {
  const [modalIsOpen, setIsOpen] = useState<boolean>(initialState);
  const [modalIsclose, setIsClose] = useState<boolean>(initialState);
  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  return { modalIsOpen, openModal, closeModal };
};
