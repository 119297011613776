import { motion } from 'framer-motion';
import React, { forwardRef } from 'react';
import { AlertContainer } from '../../styles/modal/InfoModal.styled';

interface AlertVariantsprops {
  children: React.ReactNode;
  AlertVariants: any;
}

const AlertInteraction = forwardRef<HTMLDivElement, AlertVariantsprops>(
  ({ children, AlertVariants }, ref) => {
    return (
      <motion.div
        className={`${AlertContainer.class} pointer-events-auto`}
        onClick={(e) => e.stopPropagation()}
        onTouchStart={(e) => e.stopPropagation()}
        variants={AlertVariants}
        initial="hidden"
        animate="visible"
        exit="hidden"
        ref={ref} // ref를 motion.div에 전달
      >
        {children}
      </motion.div>
    );
  },
);
AlertInteraction.displayName = 'AlertInteraction';
export default AlertInteraction;
