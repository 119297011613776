import { tw } from '../../utils/Tailwindest';

export const btnMain = tw.style({
  display: 'flex',
  alignItems: 'items-center',
  justifyContent: 'justify-center',
  textAlign: 'text-center',
  height: 'h-[8%]',
  width: 'w-[100%]',
  backgroundColor: 'bg-p3_key_color',
  color: 'text-p3_white',
  fontSize: 'text-[1rem]',
  borderRadius: 'rounded-xl',
  marginBottom: 'mb-1',
  minHeight: 'min-h-14',
  fontWeight: 'font-normal',
  '@tabletH': {
    fontSize: "tabletH:text-[clamp(1rem,3vw,1.375rem)]",
  },
});

export const btnPin = tw.style({
  fontSize: 'text-[2.25rem]',
  color: 'text-p3_padNum',
  fontWeight: 'font-thin',
  '@tabletH': {
    fontSize: "tabletH:text-[clamp(2.25rem,7vw,3.5rem)]",
  },
});

export const btnModalGrey = tw.style({
  color: 'text-p3_modal_grey',
  width: 'w-[100%]',
  fontSize: 'text-[1rem]',
  '@tabletH': {
    fontSize: "tabletH:text-[clamp(1rem,3vw,1.5rem)]",
  },
});

export const btnSheet = tw.style({
  display: 'flex',
  flexGrow: 'grow',
  fontSize: 'text-[1.125rem]',
  justifyContent: 'justify-center',
  // maxWidth: 'max-w-28',
  borderRadius: 'rounded-xl',
  backgroundColor: 'bg-transparent',
  marginTop: "mt-[2px]",
  '@tabletH': {
    fontSize: "tabletH:text-[clamp(1.125rem,3vw,1.rem)]",
  },
});

export const btnMypageGrey = tw.style({
  display: 'flex',
  alignItems: 'items-center',
  height: 'h-[2.5rem]',
  borderBottomColor: 'border-b-p3_grey',
  borderBottomWidth: 'border-b',
  '@tabletH': {
    height: "tabletH:h-[clamp(2.5rem,6vw,5rem)]",
  },
});


export const btnMyPageNav = tw.style({
  width: 'w-[1.5rem]',
  height: "h-[1.5rem]",
  marginTop: "mt-5",
  '@tabletH': {
    height: "tabletH:h-[clamp(1.5rem,4vw,3rem)]",
    width: "tabletH:w-[clamp(1.5rem,4vw,3rem)]"
  },
});

export const btnToggle = tw.style({
  width: 'w-[1.25rem]',
  height: "h-[1.25rem]",
  '@tabletH': {
    height: "tabletH:h-[clamp(1.25rem,4vw,2rem)]",
    width: "tabletH:w-[clamp(1.25rem,4vw,2rem)]"
  },
});

export const btnAddAccount = tw.style({
  fontSize: "text-[1.5rem]",
  display: "flex",
  justifyContent: "justify-between",
  marginY: "my-[0.875rem]",
  fontWeight: "font-thin",
  '@tabletH': {
    fontSize: "tabletH:text-[clamp(1.5rem,5vw,2.5rem)]",
    marginY: "tabletH:my-[clamp(0.875rem,3vw,4rem)]"
  },
  //flex justify- between my - 4 text - [clamp(1.5rem, 5vw, 2.5rem)] font - thin"
});
