import { useState } from 'react';
import { IBankInfo } from '../interface/account.interface';

export const useDropdown = () => {
  const [dropdownIsOpen, setIsOpen] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<IBankInfo>({
    accountBankName: '',
    accountMaskedAccountNumber: '',
    methodId: -1,
  });

  const toggleDropdown = () => setIsOpen(!dropdownIsOpen);
  const selectItem = ({
    accountBankName,
    accountMaskedAccountNumber,
    methodId,
  }: IBankInfo) => {
    setSelectedItem({ accountBankName, accountMaskedAccountNumber, methodId });
    setIsOpen(false);
  };

  return { dropdownIsOpen, toggleDropdown, selectedItem, selectItem };
};
