import React, { useEffect } from 'react';
import { PollingAPI } from '../api/pollingAPI';

const PoolingCodeVerify: React.FC = () => {
  const params = new URLSearchParams(window.location.search);
  const code = params.get('code') ?? '';
  const sessid = params.get('client_info') ?? '';

  useEffect(() => {
    const verify = async () => {
      if (!code || !sessid) {
        console.log('Code or sessid is null');
        window.close();
        return;
      }
      const result = await PollingAPI.setCode(sessid, code);
      console.log(result);
      if (result.status !== 200) return;
      if (result.status === 200) {
        window.close();
        return;
      }
    };

    verify();
  }, [code, sessid]);
  return <div></div>;
};

export default PoolingCodeVerify;
