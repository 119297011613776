import CryptoJS from "crypto-js";
import JSEncrypt from "jsencrypt";

const rsaPublKey =
    "-----BEGIN PUBLIC KEY-----\nMIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEA1hQH0XkhO0LmsiSgMsHXI6vRHFpxnSgMZXilcceULyvwHL7NKigqZB9yMDmDqzjwjAx3vmmAVvQrpPXCwN6eHgwgI8SiITWYFdVMFiZvQL7ouPzrELAVnBY7hv4X6lHPMNBZ3TVEkxzhvEwrzSAcZnRERwwlmoB0M/cHFABNsVh+skb30f4rSsV4U1ji/Y17WZBFyIjwlFyTRVGi4F8DdboG5bLRR5orliTmixAui+GITnlMI66FGiDf/lE88H0nu8OfVftRTkA6P41MBLFs1eVy9uQc7sWFlU06KE3MhI8Bi5jz1hshhHQwdjjoG36ea96R8tDojf8vyLifiHTzTSU+txqFs+Gmur037VCwtmsbuLDsjsJ9k1QFulduoXiotWroTnRAveiGJFR+k9OFyf77kw8Kf0FmcSgSjV2e9LgtawCkCXv9cdjG9K5L8eVkumtMqthFVEAnX+zOi++2oK1fcKKojIbcQsiGP9rTNWNZlIuAkBYwVSnit9KjVDmmOPbyfXoMyTr4griRkghmwJmZdzkLithjswix03LesgPScAMO/rV9zd6akIcnS1imIA2rqm5bT3iIem6ANmDNkwFZ+75ARysMYEuuQ00bpD23V8o8mFdeemiQm64oJQTNtitFManxi3dz78RgFZ8VewmQrJQRHWUS286uwttHK3kCAwEAAQ==\n-----END PUBLIC KEY-----";

export const encrypt = (message: string) => {
    const encrypted = encryptUniqueAES(message);
    const [ciphertext, aesKey] = encrypted;

    const rsaCipherKey = encryptByPubKey(aesKey);
    if (!rsaCipherKey) return ["", ""];

    return [ciphertext, rsaCipherKey];
};

const encryptByPubKey = (message: string) => {
    const jsencrypt = new JSEncrypt();
    jsencrypt.setPublicKey(rsaPublKey);
    const encrypted = jsencrypt.encrypt(message);
    if (!encrypted) return null;
    return encrypted;
};

const encryptUniqueAES = (message: string) => {
    const aesKey = randomString(32);
    const key = CryptoJS.enc.Utf8.parse(aesKey);
    const iv = CryptoJS.lib.WordArray.random(128 / 8);

    const encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(message), key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.ZeroPadding,
    });

    const ciphertext = iv.concat(encrypted.ciphertext).toString(CryptoJS.enc.Base64);

    return [ciphertext, aesKey];
};

export const randomString = (length: number): string => {
    let sessionRes0 = "";

    while (sessionRes0.length < length) {
        const randomIndex = Math.floor(Math.random() * 62);

        if (randomIndex >= 0 && randomIndex <= 9) {
            sessionRes0 += String.fromCharCode(randomIndex + 48); // 숫자 0-9의 ASCII 코드는 48-57
        } else if (randomIndex >= 10 && randomIndex <= 35) {
            sessionRes0 += String.fromCharCode(randomIndex + 87); // 알파벳 소문자 a-z의 ASCII 코드는 97-122
        } else {
            sessionRes0 += String.fromCharCode(randomIndex + 29); // 알파벳 대문자 A-Z의 ASCII 코드는 65-90
        }
    }

    return sessionRes0;
};