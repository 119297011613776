import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
  phoneAuth: '',
};

export const phoneAuthSlice = createSlice({
  name: "phoneAuth",
  initialState,
  reducers: {
    setPhoneAuth: (state, action: PayloadAction<string>) => {
      state.phoneAuth = action.payload;
    },
  },
});

export const { setPhoneAuth } = phoneAuthSlice.actions;

export default phoneAuthSlice.reducer;
