import React from 'react';
import { useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import BasicBox from '../../../components/box/basicBox/BasicBox';
import BackButton from '../../../components/button/BackButton';
import {
  InModalGreyBorderLine,
  RedBorderLine,
} from '../../../styles/line/BorderLine.styled';
import {
  P3WhiteTextStyled,
  P3GreyLabelStyled,
  P3GreyMypageInfoStyled,
  P3GreyTextStyled_4,
  P3SheetwhiteStyled,
  P3SheetGreystyled,
} from '../../../styles/text/P3Text.styled';
import { ModalContainerLayout } from '../../../styles/modal/InfoModal.styled';
import { btnModalGrey, btnMypageGrey } from '../../../styles/btn/Btn.styled';
import PageInteraction from '../../../components/Interection/PageInteraction';
import { useModal } from '../../../hooks/useModal.hook';
import { useOutsideClick } from '../../../hooks/useOutsideClick.hook';
import ModalInteraction from '../../../components/Interection/ModalInteraction';
import { RootState } from '../../../store/store';
import { useSelector } from 'react-redux';

const Mypage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const modalRef = useRef<HTMLDivElement>(null);
  const { modalIsOpen, openModal, closeModal } = useModal();
  const [purchaseToken, setPurchaseToken] = useState<string>('');
  useOutsideClick(modalRef, closeModal, modalIsOpen);

  const purchaseInfo = useSelector(
    (state: RootState) => state.purchase.purchaseInfo,
  );

  const holderName = purchaseInfo[0]?.holderName;

  const { PurchaseToken, MethodType, BankName, TotalCharge, MethodId } =
    location.state || {};

  const handleBackClick = () => navigate('/payment/main');

  const goPaymentSetting = () => {
    navigate('/payment/setting', {
      state: {
        MethodType: MethodType,
        BankName: BankName,
        TotalCharge: TotalCharge,
        MethodId: MethodId,
      },
    });
  };

  const goPasswordChange = () => {
    navigate('/password/change/setting');
  };

  const goCashRecipt = () => {
    navigate('/cashRecipt/issue');
  };

  useEffect(() => {
    // 계좌 등록을 하고 들어온 경우 purchaseToken 교체
    const fetch = () => {
      if (!PurchaseToken) return;
      setPurchaseToken(PurchaseToken);
    };
    fetch();
  }, [PurchaseToken]);
  return (
    <div className="flex items-center justify-center  w-screen h-[100dvh]">
      <BasicBox>
        <BackButton show={true} onClick={handleBackClick} />
        <PageInteraction
          PageTransition={{
            initial: { opacity: 0.5 },
            animate: {
              opacity: 1,
              transition: {
                ease: [0.6, 0.9, 0.6, 0.5],
              },
            },
          }}
        >
          <div className={P3WhiteTextStyled.class}>마이페이지</div>
          <div className={P3GreyLabelStyled.class}>
            나의 정보
          </div>
          <p className="text-[1.25rem] tabletH:text-[clamp(1.25rem,4vw,2rem)] mt-2 ">
            <span className="font-bold">{holderName}님</span>의 마이페이지
          </p>
          <div className={P3GreyMypageInfoStyled.class}>
            <p>전화번호 : 010-1234-5678</p>
            <p>최근 3개월 결제건수 : 0건</p>
            <p>최근 3개월 결제금액 : 12,000원</p>
          </div>
          <div className={`mt-6 ${RedBorderLine.class}`}></div>
          <div className={P3GreyMypageInfoStyled.class}>
            <p>이름, 전화번호 등 개인정보가 변경된 경우</p>
            <p>
              <span className="text-p3_key_color">본인 인증 절차</span>를 다시
              한 번 진행해 주세요
            </p>
          </div>
          <div className={P3GreyLabelStyled.class}>
            환경 설정
          </div>
          <div className={P3GreyTextStyled_4.class}>
            <button className={btnMypageGrey.class} onClick={goPasswordChange}>
              결제 비밀번호 변경
            </button>
            <button className={btnMypageGrey.class} onClick={goPaymentSetting}>
              결제 수단 설정
            </button>
            <button className={btnMypageGrey.class} onClick={goCashRecipt}>
              현금영수증 발급 설정
            </button>
            <button className={btnMypageGrey.class} onClick={openModal}>
              고객센터 문의
            </button>
            {modalIsOpen && (
              <div
                className={`${ModalContainerLayout.class} pointer-events-auto`}
                onClick={(e) => e.stopPropagation()}
                onTouchStart={(e) => e.stopPropagation()}
              >
                <ModalInteraction
                  ref={modalRef}

                  ModalVariants={{
                    hidden: {
                      y: '100vh',
                      opacity: 0,
                    },
                    visible: {
                      y: 0,
                      opacity: 1,
                      transition: {
                        type: 'spring',
                        stiffness: 250,
                        damping: 30,
                      },
                    },
                  }}
                >
                  <h2 className={P3SheetwhiteStyled.class}>
                    문의 사항이 있을경우
                    <br />
                    고객 센터 유선 문의 바랍니다.
                  </h2>
                  <p className={P3SheetGreystyled.class}>(1588-5028)</p>
                  <div className={InModalGreyBorderLine.class}></div>
                  <button className={btnModalGrey.class} onClick={closeModal}>
                    확인
                  </button>
                </ModalInteraction>
              </div>
            )}
          </div>
        </PageInteraction>

        <div className="mt-auto"></div>
      </BasicBox>
    </div>
  );
};

export default Mypage;
