import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; import success from '../../../styles/icons/success.png';
import BasicBox from '../../../components/box/basicBox/BasicBox';
import Spline from '@splinetool/react-spline';
import { P3StatusTextStyled } from '../../../styles/text/P3Text.styled';

const PasswordChangeSuccess = () => {
  const navigate = useNavigate();
  ({ width: 130, height: 130 });
  const images = [success];


  useEffect(() => {
    const timer = setTimeout(() => {
      navigate('/user/mypage');
    }, 2500);

    return () => clearTimeout(timer);
  }, [navigate]);



  return (
    <div className="flex items-center justify-center h-[100dvh]">
      <BasicBox>
        <div className="flex flex-col items-center justify-center h-screen">
          <Spline className='spline' ref={(node) => {
            if (node) {
              const canvas = node.querySelector('canvas')
              if (!canvas) return;

              const splineRoot = document.documentElement;
              splineRoot.style.setProperty('--min-size', '130px');
              splineRoot.style.setProperty('--preferred-size', '20vw');
              splineRoot.style.setProperty('--max-size', '250px');

              canvas.style.setProperty('width', 'clamp(var(--min-size), var(--preferred-size), var(--max-size))', 'important');
              canvas.style.setProperty('height', 'clamp(var(--min-size), var(--preferred-size), var(--max-size))', 'important');
            }
          }} scene="/animation/success.splinecode" />
          <p className={P3StatusTextStyled.class}>비밀번호 변경이</p>
          <p className={P3StatusTextStyled.class}>완료되었습니다.</p>
        </div>
      </BasicBox>
    </div>
  );
};

export default PasswordChangeSuccess;
