import React from 'react';
import { useNavigate } from 'react-router-dom';
import { IBackButton } from '../../interface/Btn.interface';

const BackButton: React.FC<IBackButton> = ({ show, onClick, disabled }) => {
  const navigate = useNavigate();

  const onClickFunction = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (onClick) {
      onClick(event);
      disabled;
    } else {
      navigate(-1);
    }
  };
  const renderBackButton = (
    <button onClick={onClickFunction} className='w-[50px] h-[40px]'>
      <svg
        className='h-[1.25rem] tabletH:h-[clamp(1.25rem,4vw,2rem)]'
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 13 23"
        fill="none"
      >
        <path
          d="M12.2636 0.465902C12.5619 0.764308 12.7295 1.16898 12.7295 1.59093C12.7295 2.01287 12.5619 2.41755 12.2636 2.71595L3.84098 11.1385L12.2636 19.5611C12.5534 19.8612 12.7138 20.2632 12.7102 20.6804C12.7066 21.0976 12.5392 21.4967 12.2442 21.7918C11.9491 22.0868 11.55 22.2542 11.1328 22.2578C10.7156 22.2614 10.3136 22.101 10.0135 21.8112L0.465902 12.2636C0.167585 11.9651 0 11.5605 0 11.1385C0 10.7166 0.167585 10.3119 0.465902 10.0135L10.0135 0.465902C10.3119 0.167585 10.7166 0 11.1385 0C11.5605 0 11.9651 0.167585 12.2636 0.465902Z"
          fill="#D2D2D2"
        />
      </svg>
    </button>
  );

  const renderPlaceholder = (
    <div className="invisible h-[40px] ">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="13"
        height="23"
        viewBox="0 0 13 23"
        fill="none"
      >
        <path
          d="M12.2636 0.465902C12.5619 0.764308 12.7295 1.16898 12.7295 1.59093C12.7295 2.01287 12.5619 2.41755 12.2636 2.71595L3.84098 11.1385L12.2636 19.5611C12.5534 19.8612 12.7138 20.2632 12.7102 20.6804C12.7066 21.0976 12.5392 21.4967 12.2442 21.7918C11.9491 22.0868 11.55 22.2542 11.1328 22.2578C10.7156 22.2614 10.3136 22.101 10.0135 21.8112L0.465902 12.2636C0.167585 11.9651 0 11.5605 0 11.1385C0 10.7166 0.167585 10.3119 0.465902 10.0135L10.0135 0.465902C10.3119 0.167585 10.7166 0 11.1385 0C11.5605 0 11.9651 0.167585 12.2636 0.465902Z"
          fill="#D2D2D2"
        />
      </svg>
    </div>
  );

  return show ? renderBackButton : renderPlaceholder;
};

export default BackButton;
