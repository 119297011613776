import axios, {
  AxiosError,
  AxiosInstance,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from 'axios';
import {
  BaseResponse,
  LoginDto,
  methodType,
  passwordUpdateDto,
  PaymentMethodActivateDto,
  PaymentMethodRegisterDto,
  PaymentMethodUnregisterDto,
  PurchaseDto,
  PurchaseRetrieveDto,
  RegisterDto,
} from './interfaces/mainAPI';
import {
  clearAuthCookies,
  getAuthCookies,
  setAuthCookies,
} from '../utils/cookie';
import { MAIN_SERVER_URL } from '../constants';

interface p3Auth {
  p3_clearance: string;
  p3_payload: string;
}

export class MainAPI {
  private axios: AxiosInstance;

  private isLoggedin = false;
  private static instance: MainAPI;

  constructor() {
    this.axios = axios.create({
      baseURL: MAIN_SERVER_URL,
    });

    this.axios.interceptors.request.use(this.onRequest);
    this.axios.interceptors.response.use(this.onResponse, this.onError);

    MainAPI.instance = this;
  }

  static init() {
    if (MainAPI.instance) {
      throw new Error('MainAPI is already initialized.');
    }

    MainAPI.instance = new MainAPI();
  }

  static getInstance() {
    if (!MainAPI.instance) {
      MainAPI.init();
    }

    return MainAPI.instance;
  }

  private onRequest = (config: InternalAxiosRequestConfig) => {
    const { method, url } = config;
    console.log(`[MainAPI Request] ${method?.toUpperCase()} ${url}`);

    // 인증 정보 추가
    const tokens = getAuthCookies();
    if (tokens) {
      config.headers['p3_clearance'] = tokens.p3_clearance;
      config.headers['p3_payload'] = tokens.p3_payload;
      config.headers['Access-Control-Allow-Origin'] = '*';
      config.headers['Access-Control-Allow-Headers'] = '*';
      config.headers['Access-Control-Allow-Credentials'] = 'true';

      const instance = MainAPI.getInstance();
      instance.setIsLoggedin(true);
    }

    return config;
  };

  private onResponse = (res: AxiosResponse) => {
    const { method, url } = res.config;
    const { statusCode, message, result } = res.data;

    if (!statusCode || !message) {
      console.log(
        `[MainAPI Response] ${method?.toUpperCase()} ${url} response -> HTTPSTATUS: ${res.status}, ${res.data}`,
      );
    }

    // Unauthorized, InvalidSession, ExpiredSession Errors
    if (statusCode === 3000 || statusCode === 3001 || statusCode === 3002) {
      const instance = MainAPI.getInstance();
      instance.setIsLoggedin(false);
      clearAuthCookies();
      console.log(
        `Auth Error; statusCode ${statusCode} 가 반환되어 Auth 쿠키가 초기화되었습니다.`,
      );
    }

    if (statusCode !== 200) {
      console.log(
        `[MainAPI Response] ${method?.toUpperCase()} ${url} failed -> STATUSCODE: ${statusCode}, ${message}, ${result}`,
      );
    }

    console.log(
      `[MainAPI Response] ${method?.toUpperCase()} ${url} response -> HTTPSTATUS: ${res.status}, STATUSCODE: ${statusCode}, ${message}, ${result}`,
    );

    return res;
  };

  private onError = (error: AxiosError | Error) => {
    if (axios.isAxiosError(error)) {
      const { method, url } = error.config as InternalAxiosRequestConfig;
      const { statusCode, message, result } = error.response?.data || {};

      console.log(
        `[MainAPI Error] ${method?.toUpperCase()} ${url} failed -> STATUSCODE: ${statusCode}, ${message}, ${result}`,
      );
    } else {
      console.log(`[MainAPI Error] ${error.message}`);
    }

    return Promise.reject(error);
  };

  setIsLoggedin = (isLoggedin: boolean) => {
    this.isLoggedin = isLoggedin;
  };

  getIsLoggedin = () => {
    return this.isLoggedin;
  };

  static login = async (
    purchaseToken: string,
    phoneNumberToken?: string,
  ): Promise<AxiosResponse<BaseResponse<LoginDto>>> => {
    const instance = MainAPI.getInstance();

    interface LoginBody {
      purchaseToken: string;
      phoneNumberToken?: string;
    }

    let body: LoginBody = { purchaseToken };
    if (phoneNumberToken)
      body = { ...body, phoneNumberToken: phoneNumberToken };

    const res = await instance.axios.post('/auth/login/v1', body);

    const result = setAuthCookies(
      res.data.result.p3_clearance,
      res.data.result.p3_payload,
    );
    if (!result) {
      throw new Error('Failed to set auth cookies.');
    }

    instance.setIsLoggedin(true);

    return res;
  };

  static register = async (
    purchaseToken: string,
    phoneNumberToken: string,
    passcode: string,
    code: string,
    paymentMethod: methodType,
  ): Promise<AxiosResponse<BaseResponse<RegisterDto>>> => {
    const instance = MainAPI.getInstance();
    const res = await instance.axios.post('/auth/register/v2', {
      purchaseToken,
      phoneNumberToken,
      passcode,
      code,
      paymentMethod,
    });

    const result = setAuthCookies(
      res.data.result.p3_clearance,
      res.data.result.p3_payload,
    );

    console.log('p3Cl, p3Payload', result);

    if (!result) {
      throw new Error('Failed to set auth cookies.');
    }

    instance.setIsLoggedin(true);

    return res;
  };

  static paymentUpdate = async (
    paymentMethod: methodType,
    p3Auth: p3Auth,
  ): Promise<AxiosResponse<BaseResponse<PaymentMethodRegisterDto>>> => {
    const instance = MainAPI.getInstance();
    if (!instance.getIsLoggedin()) {
      throw new Error(
        'User is not logged in, so cannot register payment method.',
      );
    }

    return instance.axios.post(
      `/payment/method/update?p3c=${p3Auth.p3_clearance}&p3p=${encodeURIComponent(p3Auth.p3_payload)}`,
      {
        paymentMethod,
      },
      {
        headers: {
          p3_clearance: p3Auth.p3_clearance,
          p3_payload: p3Auth.p3_payload,
        },
      },
    );
  };

  static paymentUnregister = async (
    bankAccountId: string,
    paymentMethod: methodType,
    p3Auth: p3Auth,
  ): Promise<AxiosResponse<BaseResponse<PaymentMethodUnregisterDto>>> => {
    const instance = MainAPI.getInstance();
    if (!instance.getIsLoggedin()) {
      throw new Error(
        'User is not logged in, so cannot unregister payment method.',
      );
    }

    return instance.axios.delete(
      `/payment/method?p3c=${p3Auth.p3_clearance}&p3p=${encodeURIComponent(p3Auth.p3_payload)}`,
      {
        data: {
          bankAccountId,
          paymentMethod,
        },
        headers: {
          p3_clearance: p3Auth.p3_clearance,
          p3_payload: p3Auth.p3_payload,
        },
      },
    );
  };

  static purchaseRetrieve = async (
    purchaseToken: string,
    p3Auth: p3Auth,
  ): Promise<AxiosResponse<PurchaseRetrieveDto>> => {
    const instance = MainAPI.getInstance();
    if (!instance.getIsLoggedin()) {
      throw new Error(
        'User is not logged in, so cannot retrieve purchase data.',
      );
    }

    return await instance.axios.post(
      `/purchase/retrieve?p3c=${p3Auth.p3_clearance}&p3p=${encodeURIComponent(p3Auth.p3_payload)}`,
      {
        purchaseToken,
      },
      {
        headers: {
          p3_clearance: p3Auth.p3_clearance,
          p3_payload: p3Auth.p3_payload,
        },
      },
    );
  };

  static paymentActivate = async (
    paymentMethod: methodType,
    paymentMethodId: number,
  ): Promise<AxiosResponse<PaymentMethodActivateDto>> => {
    const instance = MainAPI.getInstance();
    if (!instance.getIsLoggedin()) {
      throw new Error(
        'User is not logged in, so cannot register payment method.',
      );
    }

    return instance.axios.post('/payment/method/activate', {
      paymentMethodId,
      paymentMethod,
    });
  };

  static purchase = async (
    purchaseToken: string,
    passcode: string,
    paymentMethod: methodType,
    paymentMethodId: number,
    p3Auth: p3Auth,
  ): Promise<AxiosResponse<BaseResponse<PurchaseDto>>> => {
    const instance = MainAPI.getInstance();
    if (!instance.getIsLoggedin()) {
      throw new Error('User is not logged in, so cannot purchase.');
    }

    return await instance.axios.post(
      `/purchase?p3c=${p3Auth.p3_clearance}&p3p=${encodeURIComponent(p3Auth.p3_payload)}`,
      {
        purchaseToken,
        passcode,
        paymentMethod,
        paymentMethodId,
      },
      {
        headers: {
          p3_clearance: p3Auth.p3_clearance,
          p3_payload: p3Auth.p3_payload,
        },
      },
    );
  };

  static passwordUpdate = async (
    passcode: string,
    passcodeToUpdate: string,
    p3Auth: p3Auth,
  ): Promise<AxiosResponse<BaseResponse<passwordUpdateDto>>> => {
    const instance = MainAPI.getInstance();
    if (!instance.getIsLoggedin()) {
      throw new Error('User is not logged in, so cannot change password.');
    }

    return await instance.axios.post(
      `/auth/password/update?p3c=${p3Auth.p3_clearance}&p3p=${encodeURIComponent(p3Auth.p3_payload)}`,
      {
        passcode,
        passcodeToUpdate,
      },
      {
        headers: {
          p3_clearance: p3Auth.p3_clearance,
          p3_payload: p3Auth.p3_payload,
        },
      },
    );
  };
}
