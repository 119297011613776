import React from 'react';
import { INumber } from '../../../../interface/Pin.interface';
import { btnPin } from '../../../../styles/btn/Btn.styled';

const CustomNumber: React.FC<INumber> = ({ number, onNumberClick }) => {
  return (
    <button className={btnPin.class} onClick={() => onNumberClick(number)}>
      {number}
    </button>
  );
};

export default CustomNumber;
