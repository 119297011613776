import React from 'react';
import './App.css';
import 'tailwindcss/tailwind.css';
import { Routes, Route } from 'react-router-dom';

import PhoneAuthConfirm from './Auth/phone/PhoneAuthConfirm';
import PaymentMain from './Auth/Payment/PaymentMain';
import PasswordSetting from './pages/Account/password/PasswordSetting';
import PasswordSettingInput from './pages/Account/password/PasswordSettingInput';
import PaymentSuccess from './Auth/Payment/status/PaymentSuccess';
import PaymentFail from './Auth/Payment/status/PaymentFail';
import PaymentCancelSuccess from './Auth/Payment/status/PaymentCancelSuccess';
// import UserSetting from './pages/setting/user/UserSetting';
import AccountRegisterRequest from './pages/Account/AccountRegisterRequest';
// import AccountSelect from "./pages/accounts/select/AccountSelect";
import AccountSuccess from './pages/Account/status/AccountSuccess';
import ARSAuth from './Auth/ARSAuth';
import PaymentPasswordInput from './Auth/Payment/password/PaymentPasswordInput';
import PhoneAuthMain from './Auth/phone/PhoneAuthMain';
import P3AccountRegister from './pages/Account/P3AccountRegister';
import Main from './Auth/Main';
import PollingCodeVerify from './Auth/PoolingCodeVerify';
import PasswordError5Times from './Auth/Payment/status/PasswordError5Times';
import PurchaseTokenError from './Auth/PurchaseTokenError';
import Mypage from './Auth/Payment/mypage/Mypage';
import PaymentSetting from './Auth/Payment/mypage/PaymentSetting';
import PasswordChange from './Auth/Payment/mypage/change/PasswordChange';
import PasswordChangeSuccess from './Auth/Payment/status/PasswordChangeSuccess';
import CashReciptIssue from './Auth/Payment/mypage/cashRecipts/CashReciptIssue';
import PasswordChangeSetting from './Auth/Payment/mypage/change/PasswordChangeSetting';
import CashReciptInfoEdit from './Auth/Payment/mypage/cashRecipts/CashReciptInfoEdit';
import PasswordChangeSettingInput from './Auth/Payment/mypage/change/PasswordChangeSettingInput';
import CashReciptSuccess from './Auth/Payment/status/CashReciptEditSuccess';

const App: React.FC = () => {
  return (
    <div className="App">
      <Routes>
        {/* 가입 인증 페이지(가입 상태에 따라 경로 이동) */}
        <Route path="/" element={<Main />} />
        {/* account page */}
        {/* <Route path="/account/select" element={<AccountSelect />} /> */}

        <Route
          path="/account/register_request"
          element={<AccountRegisterRequest />}
        />
        {/* mypage */}
        <Route path="/user/mypage" element={<Mypage />} />

        {/* auth page */}
        <Route path="/phoneAuth/main" element={<PhoneAuthMain />} />
        <Route path="/phoneAuth/confirm" element={<PhoneAuthConfirm />} />
        <Route path="/arsAuth" element={<ARSAuth />} />
        {/* PollingCode 받아오는 페이지 */}
        <Route path="/auth/verify" element={<PollingCodeVerify />} />
        {/* payment page */}
        <Route path="/payment/main" element={<PaymentMain />} />
        <Route path="/payment/setting" element={<PaymentSetting />} />
        <Route
          path="/payment/password_input"
          element={<PaymentPasswordInput />}
        />
        <Route path="/payment/success" element={<PaymentSuccess />} />
        <Route path="/payment/fail" element={<PaymentFail />} />
        <Route
          path="/payment/cancel_success"
          element={<PaymentCancelSuccess />}
        />
        {/* password page */}
        <Route path="/password/setting" element={<PasswordSetting />} />
        <Route
          path="/password/setting_input"
          element={<PasswordSettingInput />}
        />
        <Route path="/password/change" element={<PasswordChange />} />
        <Route
          path="/password/change/setting"
          element={<PasswordChangeSetting />}
        />
        <Route
          path="/password/change/setting_input"
          element={<PasswordChangeSettingInput />}
        />
        {/* <Route path="/user/setting" element={<UserSetting />} /> */}

        {/* cashRecipt page */}
        <Route path="/cashRecipt/issue" element={<CashReciptIssue />} />
        <Route path="/cashRecipt/info_edit" element={<CashReciptInfoEdit />} />

        {/* 미래에 쓰일 자체 계좌 등록 서비스 */}
        <Route
          path="/p2_account/register"
          element={<P3AccountRegister />}
        ></Route>

        {/* Error page */}
        <Route path="password/error" element={<PasswordError5Times />} />
        <Route path="token/error" element={<PurchaseTokenError />} />

        {/* Success */}
        <Route path="/account/success" element={<AccountSuccess />} />
        <Route
          path="/password/change/success"
          element={<PasswordChangeSuccess />}
        />
        <Route path="/cashRecipt/success" element={<CashReciptSuccess />} />
        {/* fail */}
      </Routes>
    </div>
  );
};

export default App;
