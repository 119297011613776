import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useConfirm } from '../../hooks/useConfirm.hook';
import BasicBox from '../../components/box/basicBox/BasicBox';
import BackButton from '../../components/button/BackButton';
import BasicButton from '../../components/button/ConfirmButton';
import { useState, useRef } from 'react';
import {
  GreyBorderLine,
  InAlertGreyBorderLine,
  RedBorderLine,
} from '../../styles/line/BorderLine.styled';
import {
  P3WhiteTextStyled,
  P3GreyLabelStyled,
  P3AlertTextWhite,
  P3AlertTextgrey,
  P3GreyInputStyled,
  P3GreyTextStyled_mt,
} from '../../styles/text/P3Text.styled';

import { btnModalGrey } from '../../styles/btn/Btn.styled';
import PageInteraction from '../../components/Interection/PageInteraction';
import { PollingAPI } from '../../api/pollingAPI';
import { useDispatch, useSelector } from 'react-redux';
import { setPhoneAuth } from '../../features/phoneAuthSlice';
import { useModal } from '../../hooks/useModal.hook';
import { useOutsideClick } from '../../hooks/useOutsideClick.hook';
import AlertInteraction from '../../components/Interection/AlertInteraction';
import { MainAPI } from '../../api/mainAPI';
import { setP3Auth } from '../../features/p3Slice';
import { RootState } from '../../store/store';
import { phoneAxiosErrorHandler } from '../../utils/error/phoneErrorHandler';
import { AlertContainerLayout } from '../../styles/modal/InfoModal.styled';


const PhoneAuthConfirm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const modalRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();
  const goToPrevious = () => navigate(-1);
  const { PhoneNumber } = location.state || {};
  const [authNumberInput, setAuthNumberInput] = useState<string>('');
  const { modalIsOpen, openModal, closeModal } = useModal();
  useOutsideClick(modalRef, closeModal, modalIsOpen);

  const [confirmBtnDisabled, setConfirmBtnDisabled] = useState<boolean>(false);

  const authModalConfirmHandler = () => {
    setConfirmBtnDisabled(false);
    closeModal();
  };

  const PurchaseToken = useSelector(
    (state: RootState) => state.purchaseToken.purchaseToken,
  );

  // 인증번호 받으면 백에 인증 요청
  const AuthNumberConfirm = async () => {
    setConfirmBtnDisabled(true);
    try {
      const formatteedPhoneNumber = PhoneNumber.replace(/-/g, '');
      const res = await PollingAPI.authCodeVerify(
        formatteedPhoneNumber,
        authNumberInput,
      );
      console.log('폰 인증?', res.data);
      const phoneAuthData: string = res.data;
      dispatch(setPhoneAuth(phoneAuthData));

      try {
        const loginRes = await MainAPI.login(
          PurchaseToken.purchaseToken,
          res.data,
        );
        if (loginRes.data.statusCode === 200) {
          dispatch(setP3Auth(loginRes.data.result));
          navigate('/payment/main', {
            state: { PhoneAuthedCode: res.data },
          });
          return;
        }
      } catch (error) {
        console.log('로그인 실패');
        console.log(error);
        // if (axios.isAxiosError<IServerError>(error)) {
        //   const axiosError = error as AxiosError<IServerError>;
        //   if (axiosError.response) {
        //     const serverError: IServerError = axiosError.response.data;
        //     console.log(serverError);
        //     // 비밀번호 5회 틀린 유저가 로그인 한 경우
        //     if (serverError.statusCode === 3003) {
        //       navigate('/password/error');
        //     }
        //     // 알 수 없는 에러인 경우 => 주로 PurchaseToken이 없는 경우
        //     if (serverError.statusCode === 5000) {
        //       // purchaseToken 다시 가져오게 고객사에 돌려 보냄
        //       SendMessage('error', null);
        //     }
        //     // 전화번호로 사용자를 찾을 수 없는 경우
        //     if (serverError.statusCode === 1050) {
        //       navigate('/account/register_request', {
        //         state: {
        //           PhoneAuthedCode: res.data,
        //         },
        //       });
        //     }
        //     // 사용자를 찾을 수 없는 경우
        //     if (serverError.statusCode === 1013) {
        //       navigate('/phoneAuth/main');
        //     }
        //   }
        // }
        phoneAxiosErrorHandler(error, navigate, res.data);
        return;
      }

      // 인증 성공 하면 다음으로
      navigate('/account/register_request', {
        state: { PhoneAuthedCode: res.data },
      });
    } catch (error) {
      openModal();
      console.log(error);
      return;
    }
  };

  const confirmAction = useConfirm('', AuthNumberConfirm, goToPrevious);
  return (
    <div className="flex items-center justify-center w-screen h-[100dvh]">
      <BasicBox>
        <BackButton show={true} />
        <PageInteraction
          PageTransition={{
            initial: { opacity: 0.5 },
            animate: {
              opacity: 1,
              transition: {
                ease: [0.6, 0.9, 0.6, 0.5],
              },
            },
          }}
        >
          {modalIsOpen && (
            <div
              className={`${AlertContainerLayout.class} pointer-events-auto`}
              onClick={(e) => e.stopPropagation()}
              onTouchStart={(e) => e.stopPropagation()}
            >
              <AlertInteraction
                ref={modalRef}
                AlertVariants={{
                  hidden: {
                    scale: 0.75, // 축소된 상태에서 시작
                    opacity: 0,
                  },
                  visible: {
                    scale: 1, // 원래 크기로 확대
                    opacity: 1,
                    transition: {
                      duration: 0.2, // 애니메이션 지속 시간 설정
                      ease: 'easeInOut', // 시작과 끝에서는 부드럽게, 중간에는 빠르게
                    },
                  },
                }}
              >
                <h3 className={P3AlertTextWhite.class}>
                  인증번호가 일치하지 않습니다
                </h3>
                <p className={P3AlertTextgrey.class}>입력하신 인증번호를</p>
                <p className={P3AlertTextgrey.class}>
                  다시 한 번 확인해 주세요
                </p>
                <div className={InAlertGreyBorderLine.class}></div>
                <button
                  className={btnModalGrey.class}
                  onClick={authModalConfirmHandler}
                >
                  확인
                </button>
              </AlertInteraction>
            </div>
          )}
          <div className={P3WhiteTextStyled.class}>인증 번호 확인</div>

          {/* <div className={P3GreyTextStyled.class}>
            문자로 전달된 인증번호를 입력해주세요.
          </div> */}
          <label className={P3GreyLabelStyled.class}>인증번호</label>
          <input
            type="text"
            placeholder="6자리 숫자"
            className={`${authNumberInput.length === 6
              ? RedBorderLine.class
              : GreyBorderLine.class
              } placeholder-p3_placeholder ${P3GreyInputStyled.class}`}
            value={authNumberInput}
            onChange={(e) => setAuthNumberInput(e.target.value)}
          ></input>
          <div className={P3GreyTextStyled_mt.class}>
            {PhoneNumber}로 인증번호가 <br />
            전송되었습니다.
          </div>
        </PageInteraction>
        <div className="mt-auto"></div>
        <BasicButton onClick={confirmAction} disabled={confirmBtnDisabled}>
          인증하기
        </BasicButton>
      </BasicBox>
    </div>
  );
};

export default PhoneAuthConfirm;
