import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PaymentMethodDto } from '../api/interfaces/mainAPI';

export interface ExtendedPurchaseState {
  purchaseInfo: PaymentMethodDto[];
}

// 초기 상태 값 설정
const initialState: ExtendedPurchaseState = {
  purchaseInfo: [],
};

export const purchaseSlice = createSlice({
  name: 'purchase',
  initialState,
  reducers: {
    setPurchaseInfo: (state, action: PayloadAction<PaymentMethodDto[]>) => {
      state.purchaseInfo = action.payload;
    },
    removePurchaseInfo: (state, action: PayloadAction<string>) => {
      state.purchaseInfo = state.purchaseInfo.filter(
        (method) => method.bankAccountId !== action.payload,
      );
    },
  },
});

export const { setPurchaseInfo, removePurchaseInfo } = purchaseSlice.actions;

export default purchaseSlice.reducer;
