import { tw } from '../../utils/Tailwindest';

export const PWInteraction = tw.style({
    display: 'flex',
    flexDirection: 'flex-col',
    height: 'h-full',
    width: 'w-full',
});


export const PWInteraction_2 = tw.style({
    display: 'flex',
    flexDirection: 'flex-col',
    alignItems: 'items-center',
    justifyContent:"justify-center",
    height: 'h-full',
    width: 'w-full',
});

export const PinRenderInput = tw.style({
    display: "inline-block",
    width: "w-[2rem]",
    height: "h-[3.25rem]",
    lineHeight: "leading-[45px]",
    textAlign: "text-center",
    borderTopWidth: "border-t-0",
    borderLeftWidth: "border-l-0",
    borderRightWidth: "border-r-0",
    borderBottomWidth: "border-b",
    marginTop: "mt-[0.5rem]",
    marginRight: "mr-[0.5rem]",
    marginBottom: "mb-0",
    marginLeft: "ml-[0.5rem]",
    backgroundColor: "bg-transparent",
    fontSize: 'text-[1rem]',
    '@tabletH': {
        fontSize: "tabletH:text-[clamp(1rem,5vw,2rem)]",
        height: "tabletH:h-[clamp(3.25rem,7vw,5rem)]",
        width: "tabletH:w-[clamp(2rem,8vw,4rem)]",
        marginTop: "tabletH:mt-[clamp(0.5rem,3vw,2rem)]",
        marginRight: "tabletH:mr-[clamp(0.5rem,3vw,2rem)]",
        marginLeft: "tabletH:ml-[clamp(0.5rem,3vw,1rem)]"
    },
});

export const PWNumberpad = tw.style({
    display: "grid",
    gridTemplateColumns: "grid-cols-3",
    width: "w-[18.75rem]",
    height: "h-[16rem]",
    marginTop: "mt-[2rem]",
    '@tabletH': {
        width: "tabletH:w-[clamp(18.75rem,80vw,40rem)]",
        height: "tabletH:h-[clamp(16rem,70vw,31rem)]",
        marginTop: "tabletH:mt-[clamp(2rem,5vw,5rem)]",
    },
});
