import React from 'react';
import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useConfirm } from '../../../hooks/useConfirm.hook';
import BasicBox from '../../../components/box/basicBox/BasicBox';
import BackButton from '../../../components/button/BackButton';
import {
  GreyBorderLine,
  InAlertGreyBorderLine,
  InModalGreyBorderLine,
  InModalLengthGreyBorderLine,
  RedBorderLine,
} from '../../../styles/line/BorderLine.styled';
import BasicButton from '../../../components/button/ConfirmButton';
import {
  P3GreyLabelStyled,
  P3AlertTextgrey,
  P3SheetwhiteStyled_2,
  P3AlertTextWhite,
  P3WhiteTextStyled_mb,
  P3GreyTextStyled_mt,
} from '../../../styles/text/P3Text.styled';
import { useModal } from '../../../hooks/useModal.hook';
import { useDropdown } from '../../../hooks/useDropdown.hook';
import PageInteraction from '../../../components/Interection/PageInteraction';
import { useDispatch, useSelector } from 'react-redux';
import { setPurchaseInfo } from '../../../features/purchaseSlice';
import { setTotalCharge } from '../../../features/totalChargeSlice';
import { RootState } from '../../../store/store';
import { MainAPI } from '../../../api/mainAPI';
import { PaymentMethodDto, methodType } from '../../../api/interfaces/mainAPI';
import {
  AlertContainerLayout,
  ModalContainerLayout,
} from '../../../styles/modal/InfoModal.styled';
import { GetCodeDto } from '../../../api/interfaces/pollingAPI';
import { useOpenbankingLink } from '../../../hooks/useOpenbankingLink';
import { PollingAPI } from '../../../api/pollingAPI';
import ModalInteraction from '../../../components/Interection/ModalInteraction';
import { btnAddAccount, btnModalGrey, btnSheet, btnToggle } from '../../../styles/btn/Btn.styled';
import { OPENBANKING_REDIRECT_URL } from '../../../constants';
import axios, { AxiosError } from 'axios';
import AlertInteraction from '../../../components/Interection/AlertInteraction';
import Lottie from 'lottie-react';
import LottieLoading from '../../../styles/icons/Loading.json';
import { IServerError } from '../../../interface/serverError.interface';
import { inputLoading } from '../../../styles/loading/Loading.styled';
import { dropdownFlex, dropdownLayout, toggleFlex } from '../../../styles/input/Input.styled';

const PaymentSetting = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const modalRef = useRef<HTMLDivElement>(null);
  const toggleModalRef = useRef<HTMLDivElement>(null);
  const addAccountButtonRef = useRef<HTMLDivElement>(null);

  const [holderName, setHolderName] = useState<string>('');
  const [bankName, setBankName] = useState<string>('');
  const [maskedAccountNumber, setMaskedAccountNumber] = useState<string>('');
  const [methodType, setMethodType] = useState<methodType>('');
  const [purchaseToken, setPurchaseToken] = useState<string>('');
  const [realprice, setRealprice] = useState<number>();
  const [methodId, setMethodId] = useState<number>(-1);
  // 사용자한테 보여주는 string type 금액
  const [commaPrice, setCommaPrice] = useState<string>('');
  const { PhoneAuthedCode, MethodType, BankName, TotalCharge, MethodId } =
    location.state || {};

  const [PollingCode, setPollingCode] = useState<GetCodeDto | any>();
  const [childWindow, setChildWindow] = useState<Window | null>(null);
  const dispatch = useDispatch();
  const { openbankingLink, uuid } = useOpenbankingLink(
    OPENBANKING_REDIRECT_URL,
  );
  const phoneAuthInfo = useSelector(
    (state: RootState) => state.phoneAuth.phoneAuth,
  );

  const purchaseInfo = useSelector(
    (state: RootState) => state.purchase.purchaseInfo,
  );

  const PurchaseToken = useSelector(
    (state: RootState) => state.purchaseToken.purchaseToken,
  );
  const p3Auth = useSelector((state: RootState) => state.p3Auth);

  // 계좌 추가 모달
  const {
    modalIsOpen: addAccountModalIsOpen,
    openModal: addAccountOpenModal,
    closeModal: addAccountCloseModal,
  } = useModal();
  // 세션만료 모달
  const {
    modalIsOpen: sessionCheckModalIsOpen,
    openModal: sessionCheckOpenModal,
    closeModal: sessionCheckCloseModal,
  } = useModal();
  // 계좌 추가 실패 모달
  const {
    modalIsOpen: accountAddFailModalIsOpen,
    openModal: accountAddFailOpenModal,
    closeModal: accountAddFailCoseModal,
  } = useModal();
  // 선택 창 커스텀 훅
  const {
    selectedItem,
    selectItem,
    dropdownIsOpen: isDropdownOpen,
    toggleDropdown,
  } = useDropdown();

  //   useQutsideClick(
  //     toggleModalRef,
  //     toggleDropdown,
  //     isDropdownOpen,
  //     addAccountButtonRef,
  //   );

  const openBankingReqHandler = () => {
    const newChildWindow = window.open(
      openbankingLink,
      '오픈뱅킹 인증',
      'top=10, left=10, width=500, height=800',
    );

    if (newChildWindow) {
      setChildWindow(newChildWindow);
    }
  };

  // PurchaseToken 관리
  useEffect(() => {
    // 계좌 등록을 하고 들어온 경우 purchaseToken 교체
    const fetch = () => {
      if (!PurchaseToken) return;
      setPurchaseToken(PurchaseToken.purchaseToken);
    };
    fetch();
  }, [PurchaseToken]);

  // Polling Server에서 Code 가져오는 함수
  const getCodeFromPS = async () => {
    const res = await PollingAPI.getCode(uuid);
    console.log('pollingCode', res.data.code);

    if (!res.data.code) {
      console.log('PollingCode get Request failed');
      return;
    }

    setPollingCode(res.data.code);
  };

  const methods: PaymentMethodDto[] = [];
  const updateMethodPosition = (selectedMethodId: number) => {
    const selectedIndex = methods.findIndex(
      (method) => method.id === selectedMethodId,
    );
    if (selectedIndex > -1) {
      const selectedMethod = methods.splice(selectedIndex, 1)[0];
      methods.unshift(selectedMethod);
      dispatch(setPurchaseInfo(methods)); // 상태 업데이트
    }
  };

  const purchaseTokenLookup = async () => {
    console.log(p3Auth);
    const res = await MainAPI.purchaseRetrieve(purchaseToken, p3Auth);
    console.log('PurchaseRetrieve 정보', res);
    if (res.data && res.data.result) {
      const { methods, totalCharge } = res.data.result;
      //   if (totalCharge === 0 || (methods && methods.length === 0)) {
      //     navigate('/account/register_request', {
      //       state: {
      //         PhoneAuthedCode: PhoneAuthedCode,
      //       },
      //     });
      //     return;
      //   }
      console.log('methods?', methods);
      console.log('totalCharge', totalCharge);
      setRealprice(totalCharge);
      const commaPrice: string = totalCharge.toLocaleString();
      setCommaPrice(commaPrice);
      dispatch(setPurchaseInfo(methods));
      dispatch(setTotalCharge(totalCharge));
      const firstMethod: PaymentMethodDto = methods[0];
      if (
        !selectedItem ||
        !(
          selectedItem.accountBankName &&
          selectedItem.accountMaskedAccountNumber &&
          selectedItem.methodId
        )
      ) {
        console.log('MethodID', firstMethod.id);
        setBankName(firstMethod.bankName);
        setMaskedAccountNumber(firstMethod.maskedAccountNumber);
        setMethodId(firstMethod.id);
        setHolderName(firstMethod.holderName);
        setMethodType(firstMethod.methodType);
      } else {
        setBankName(selectedItem.accountBankName);
        console.log('선택 후 은행명', selectedItem.accountBankName);
        setMaskedAccountNumber(selectedItem.accountMaskedAccountNumber);
        console.log('선택 후 MethodID', selectedItem.methodId);
        setMethodId(selectedItem.methodId);
      }
      updateMethodPosition(selectedItem.methodId);
    }
  };

  const changeAccountPayments = async () => {
    const paymentMethod = methodType;
    const paymentMethodId = methodId;
    try {
      const res = await MainAPI.paymentActivate(paymentMethod, paymentMethodId);
      if (res.data) {
        console.log('계좌가 성공적으로 업데이트 되었습니다.');
        console.log(paymentMethod);
        console.log(paymentMethodId);
        updateMethodPosition(methodId);
      } else {
        console.error('API 응답에 유효한 데이터가 없습니다.');
      }
    } catch (error) {
      console.error('대표 계좌 설정 실패:', error);
    }
  };

  const addAccountLookUp = async () => {
    try {
      const res = await MainAPI.purchaseRetrieve(purchaseToken, p3Auth);
      console.log('addAccountLookUp data', res.data);
      if (res.data && res.data.result) {
        const { methods } = res.data.result;
        console.log('methods?', methods);

        const newAccountInfo = methods[methods.length - 1];
        setBankName(newAccountInfo.bankName);
        setMaskedAccountNumber(newAccountInfo.maskedAccountNumber);
        setMethodId(newAccountInfo.id);
        selectItem({
          accountBankName: newAccountInfo.bankName,
          accountMaskedAccountNumber: newAccountInfo.maskedAccountNumber,
          methodId: newAccountInfo.id,
        });
      }
    } catch (error) {
      if (axios.isAxiosError<IServerError>(error)) {
        const axiosError = error as AxiosError<IServerError>;
        if (axiosError.response) {
          const serverError: IServerError = axiosError.response.data;
          if (serverError.statusCode === 3002) {
            // 세션 만료 모달
            sessionCheckOpenModal();
          }
        }
      }
    }
  };

  // 사용자 구매 정보 조회
  useEffect(() => {
    console.log(purchaseToken);
    if (purchaseToken) purchaseTokenLookup();
  }, [purchaseToken, selectedItem]);

  useEffect(() => {
    checkWindowOpen();
  }, [PollingCode]);

  const checkWindowOpen = async () => {
    const phoneAuthedCode = phoneAuthInfo;
    const paymentMethodCode = purchaseInfo && purchaseInfo[0]?.methodType;
    if (!PollingCode) return;

    if (PollingCode) {
      try {
        if (paymentMethodCode && p3Auth) {
          const res = await MainAPI.paymentUpdate(paymentMethodCode, p3Auth);
          console.log('paymentUpdate', res);
          addAccountLookUp();
        }
      } catch (error) {
        console.log(error);
        // 계좌 추가 업데이트 실패 모달
        accountAddFailOpenModal();
      }
      navigate('/payment/main', {
        state: {
          PhoneAuthedCode: phoneAuthedCode,
        },
      });
      addAccountCloseModal();
    }
  };
  // "120220171688941193741805" - 진짜 계좌 bankID
  useEffect(() => {
    const intervalId = setInterval(() => {
      if (childWindow && childWindow.closed) {
        getCodeFromPS();
        checkWindowOpen();
        console.log('오픈 뱅킹 창이 닫혔네?');
        clearInterval(intervalId);
        addAccountCloseModal();
      }
    }, 500);
    return () => clearInterval(intervalId);
  }, [childWindow]);

  const goToNext = () => {
    navigate('/payment/password_input', {
      state: {
        MethodType: methodType,
        BankName: bankName,
        TotalCharge: realprice,
        MethodId: methodId,
      },
    });
  };

  const goToPrevious = () => navigate(-1);

  const confirmAction = useConfirm(``, goToNext, goToPrevious);

  return (
    <div className="flex items-center justify-center h-[100dvh] w-screen">
      <BasicBox>
        <BackButton show={true} onClick={goToPrevious} />
        <PageInteraction
          PageTransition={{
            initial: { opacity: 0.5 },
            animate: {
              opacity: 1,
              transition: {
                ease: [0.6, 0.9, 0.6, 0.5],
              },
            },
          }}
        >
          {/* 뒤로가기 클릭 시 띄울 모달 창 */}

          <div className={P3WhiteTextStyled_mb.class}>결제수단 설정</div>
          <div className={`gap-1 ${P3GreyTextStyled_mt.class}`}>
            <div>계좌 삭제 또는 재등록을 희망하실 경우</div>
            <div>원하시는 계좌를 3초간 눌러주세요</div>
          </div>


          <label
            className={P3GreyLabelStyled.class}
          >
            대표계좌 설정하기
          </label>
          <div
            className={
              bankName.length ? RedBorderLine.class : GreyBorderLine.class
            }
          >
            {/* 토글 버튼 */}
            <button className="w-full h-[2.25rem] tabletH:h-[clamp(2.25rem,7vw,5rem)]">
              <div
                onClick={toggleDropdown}
                className={toggleFlex.class}
                ref={toggleModalRef}
              >
                {purchaseInfo && bankName && maskedAccountNumber ? (
                  <>
                    <div className="text-[1.5rem] tabletH:text-[clamp(1.5rem,5vw,2.25rem)] font-thin mr-2">
                      {selectedItem.accountBankName || bankName}
                      &nbsp;
                      <span className="text-[1.25rem] tabletH:text-[clamp(1.25rem,4vw,2rem)]">
                        {selectedItem.accountMaskedAccountNumber ||
                          maskedAccountNumber}
                      </span>
                    </div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className={btnToggle.class}
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M19 9l-7 7-7-7"
                      />
                    </svg>
                  </>
                ) : (
                  <Lottie
                    animationData={LottieLoading}
                    loop={true}
                    className={inputLoading.class}
                  />
                )}
              </div>
            </button>
          </div>

          {isDropdownOpen && (
            <ul className={`custom-scrollbar ${dropdownLayout.class}`}>
              {purchaseInfo?.map(
                (account: PaymentMethodDto, index: number) => (
                  <li
                    key={index}
                    onClick={() =>
                      selectItem({
                        accountBankName: account.bankName,
                        accountMaskedAccountNumber:
                          account.maskedAccountNumber,
                        methodId: account.id,
                      })
                    }
                    className={dropdownFlex.class}
                  >
                    <div className="text-[1.5rem] tabletH:text-[clamp(1.5rem,5vw,2.5rem)] font-thin">
                      {(() => {
                        const bankName = account.bankName;
                        const bankIndex = bankName.indexOf('은행');
                        return bankIndex > -1
                          ? bankName.substring(0, bankIndex)
                          : bankName;
                      })()}
                      &nbsp;
                      {account.maskedAccountNumber.slice(0, 4)}
                    </div>
                  </li>
                ),
              )}

              {/* plus Button */}
              <div
                className={btnAddAccount.class}
                onClick={addAccountOpenModal}
                ref={addAccountButtonRef}
              >
                계좌 추가
                {/* <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="10"
                    height="20"
                    viewBox="0 0 10 10"
                    fill="none"
                    className="mt-1.5 mr-2"
                  >
                    <path d="M0 5H10" stroke="#6F6F70" strokeWidth="1.5" />
                    <path d="M5 0L5 10" stroke="#6F6F70" strokeWidth="1.5" />
                  </svg> */}
              </div>
              {addAccountModalIsOpen && (
                <div className={ModalContainerLayout.class}>
                  <ModalInteraction
                    ref={modalRef}
                    ModalVariants={{
                      hidden: {
                        y: '100vh',
                        opacity: 0,
                      },
                      visible: {
                        y: 0,
                        opacity: 1,
                        transition: {
                          type: 'spring',
                          stiffness: 400,
                          damping: 40,
                        },
                      },
                    }}
                  >
                    <h2 className={P3SheetwhiteStyled_2.class}>계좌 추가</h2>
                    <p className="text-[0.875rem] tabletH:text-[clamp(0.875rem,3vw,1.725rem)]">계좌 추가 절차가 진행됩니다.</p>
                    <p className="text-[0.875rem] tabletH:text-[clamp(0.875rem,3vw,1.725rem)]">추가 하시겠습니까?</p>
                    <div className={InModalGreyBorderLine.class}></div>
                    <div className="flex justify-around gap-x-3">
                      <button
                        onClick={addAccountCloseModal}
                        className={`${btnSheet.class} `}
                      >
                        아니요
                      </button>
                      <div
                        className={InModalLengthGreyBorderLine.class}
                      ></div>
                      <button
                        className={`text-p3_key_color ${btnSheet.class} `}
                        onClick={openBankingReqHandler}
                      >
                        추가 진행
                      </button>
                    </div>
                  </ModalInteraction>
                </div>
              )}
              <div className="border-b border-p3_grey"></div>
            </ul>
          )}
          {sessionCheckModalIsOpen && (
            <div
              className={`${AlertContainerLayout.class} pointer-events-auto`}
            >
              <AlertInteraction
                ref={modalRef}
                AlertVariants={{
                  hidden: {
                    scale: 0.75,
                    opacity: 0,
                  },
                  visible: {
                    scale: 1,
                    opacity: 1,
                    transition: {
                      duration: 0.2,
                      ease: 'easeInOut',
                    },
                  },
                }}
              >
                <h3 className={P3AlertTextWhite.class}>오류</h3>
                <p className={P3AlertTextgrey.class}>
                  세션이 만료되었습니다.
                </p>
                <p className={P3AlertTextgrey.class}>다시 로그인해 주세요.</p>
                <div className={InAlertGreyBorderLine.class}></div>
                <button
                  className={btnModalGrey.class}
                  onClick={() => navigate('/')}
                >
                  확인
                </button>
              </AlertInteraction>
            </div>
          )}

          {accountAddFailModalIsOpen && (
            <div
              className={`${AlertContainerLayout.class} pointer-events-auto`}
            >
              <AlertInteraction
                ref={modalRef}
                AlertVariants={{
                  hidden: {
                    scale: 0.75,
                    opacity: 0,
                  },
                  visible: {
                    scale: 1,
                    opacity: 1,
                    transition: {
                      duration: 0.2,
                      ease: 'easeInOut',
                    },
                  },
                }}
              >
                <h3 className={P3AlertTextWhite.class}>오류</h3>
                <p className={P3AlertTextgrey.class}>
                  계좌추가 업데이트에 실패하였습니다.
                </p>
                <p className={P3AlertTextgrey.class}>다시 시도해 주세요.</p>
                <div className={InAlertGreyBorderLine.class}></div>
                <button
                  className={btnModalGrey.class}
                  onClick={accountAddFailCoseModal}
                >
                  확인
                </button>
              </AlertInteraction>
            </div>
          )}
        </PageInteraction>
        <div className="mt-auto"></div>
        <BasicButton onClick={changeAccountPayments}>
          변경사항 저장
        </BasicButton>
      </BasicBox>
    </div>
  );
};

export default PaymentSetting;
