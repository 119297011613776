import Cookies from 'universal-cookie';

export const cookie = new Cookies();

export const getP3Payload = () => {
  return cookie.get('p3_payload');
};

export const getP3Clearance = () => {
  return cookie.get('p3_clearance');
};

export const getAuthCookies = (): {
  p3_clearance: string;
  p3_payload: string;
} | null => {
  const c = cookie.get('p3_clearance');
  const p = cookie.get('p3_payload');

  if (!c || !p) {
    return null;
  }

  return {
    p3_clearance: c,
    p3_payload: p,
  };
};

export const setAuthCookies = (
  p3_clearance: string,
  p3_payload: string,
): boolean => {
  if (!p3_clearance || !p3_payload) {
    return false;
  }

  cookie.set('p3_clearance', p3_clearance, { path: '/' });
  cookie.set('p3_payload', p3_payload, { path: '/' });
  return true;
};

export const clearAuthCookies = () => {
  cookie.remove('p3_clearance');
  cookie.remove('p3_payload');
};
