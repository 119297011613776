import { tw } from '../../utils/Tailwindest';

export const GreyBorderLine = tw.style({
  borderBottomWidth: 'border-b',
  borderColor: 'border-p3_grey',
  borderBottomRadius: 'rounded-b-none',
});

export const RedBorderLine = tw.style({
  borderBottomWidth: 'border-b',
  borderColor: 'border-p3_key_color',
  borderBottomRadius: 'rounded-b-none',
});

export const InModalGreyBorderLine = tw.style({
  marginY: 'my-[0.75rem]',
  marginTop: "mt-[1rem]",
  borderBottomWidth: 'border-b',
  borderColor: 'border-p3_modal_greyLine',
  marginX: 'mx-1',
  '@tabletH': {
    marginY: "tabletH:my-[clamp(0.75rem,3vw,1.125rem)]",
    marginTop:"tabletH:mt-[clamp(1rem,3vw,1.375rem)]"
  },
});
export const InAlertGreyBorderLine = tw.style({
  margin: 'm-[1rem]',
  borderBottomWidth: 'border-b',
  borderColor: 'border-p3_modal_greyLine',
  '@tabletH': {
    margin: "tabletH:m-[clamp(1rem,3vw,1.25rem)]",
  },
});

export const InModalLengthGreyBorderLine = tw.style({
  borderWidth: 'border',
  borderColor: 'border-p3_modal_greyLine',
  height: 'h-[2rem]',
  '@tabletH': {
    height: "tabletH:h-[clamp(2rem,3vw,4rem)]",
  },
});
