import { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { randomString } from '../utils/encrypt';
import { OPENBANKING_OAUTH_BASEURL, OPENBANKING_CLIENT_ID } from '../constants';

export const useOpenbankingLink = (redirectURL: string) => {
  const [openbankingLink, setOpenbankingLink] = useState('');
  const [uuid, setUuid] = useState('');

  useEffect(() => {
    const generateOpenbankingLink = () => {
      const newUuid = uuidv4();
      setUuid(newUuid);

      const csrfToken = randomString(32);
      const url = new URL('/oauth/2.0/authorize', OPENBANKING_OAUTH_BASEURL);
      const link = `${url.toString()}?response_type=code&client_id=${OPENBANKING_CLIENT_ID}&authorized_cert_yn=N&scope=login+transfer+inquiry&state=${csrfToken}&client_info=${newUuid}&auth_type=0&redirect_uri=${redirectURL}`;
      setOpenbankingLink(link);
    };

    generateOpenbankingLink();
  }, [redirectURL]);

  return { openbankingLink, uuid };
};
