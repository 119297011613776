import { tw } from "../../utils/Tailwindest";
export const toggleFlex = tw.style({
    display: "flex",
    justifyContent: "justify-between",
    alignItems: "items-center",
    marginBottom: "mb-2",
    height: "h-[2.25rem]",
    "@tabletH": { height: "tabletH:h-[clamp(2.25rem,5vw,5rem)]" }
})
export const dropdownLayout = tw.style({
    height: "h-[5rem]",
    overflow: "overflow-y-auto",
    cursor: "cursor-pointer",
    backgroundColor: "bg-p3_background",
    color: "text-p3_grey",
    "@tabletH": { height: "tabletH:h-[clamp(5rem,20vw,9rem)]" }
})

export const dropdownFlex = tw.style({
    display: "flex",
    justifyContent: "justify-between",
    alignItems: "items-center",
    marginTop: "mt-[1rem]",
    height: "h-[1rem]",
    ":hover": {
        color: "hover:text-p3_key_color"
    },
    "@tabletH": {
        height: "tabletH:h-[clamp(1rem,7vw,1.75rem)]",
        marginTop: "tabletH:mt-[clamp(1rem,5vw,2.25rem)]"
    }
})
