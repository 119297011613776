import { MessageStatusType } from './message';

interface ISendMessage {
  (status: MessageStatusType, msg: string | null, ...others: any[]): void;
}

declare global {
  interface Window {
    Point3WidgetBridge: any | undefined;
    webkit: any | undefined;
    ReactNativeWebView: any | undefined;
  }
}

export const SendMessage: ISendMessage = (status, msg, ...others) => {
  try {
    if (window.parent) {
      console.log("Message Sent 1")
      window.parent.postMessage({ status, msg, ...others }, '*');
    }

    if (window.Point3WidgetBridge) {

      console.log("Message Sent 2")
      window.Point3WidgetBridge.postMessage(
        JSON.stringify({ status, msg, ...others }),
      );
    }

    if (window.webkit.messageHandlers.point3WidgetHandler) {
      console.log("Message Sent 3")
      window.webkit.messageHandlers.point3WidgetHandler.postMessage({
        status,
        msg,
        ...others,
      });
    }

    if (window.ReactNativeWebView) {
      console.log("Message Sent 4")
      window.ReactNativeWebView.postMessage(
        JSON.stringify({ status, msg, ...others }),
      );
    }
  } catch (e) {
    console.error(e);
  }
};
