import React from 'react';
import { boxMain } from '../../../styles/box/BoxMain.styled';

interface BasicBoxProps {
  children: React.ReactNode;
}

const BasicBox: React.FC<BasicBoxProps> = ({ children }) => {
  return <div className={boxMain.class}>{children}</div>;
};

export default BasicBox;
