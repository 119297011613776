import { motion } from 'framer-motion';
import React from 'react';

interface PageInteractionProps {
  children: React.ReactNode;
  PageTransition: any;
}

const PageInteraction: React.FC<PageInteractionProps> = ({
  children,
  PageTransition,
}) => {
  return (
    <motion.div
      className='w-full flex flex-col'
      initial="initial"
      animate="animate"
      exit="exit"
      variants={PageTransition}
    >
      {children}
    </motion.div>
  );
};

export default PageInteraction;
