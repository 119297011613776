import React from 'react';
import BasicBox from '../components/box/basicBox/BasicBox';
import BackButton from '../components/button/BackButton';
import BasicButton from '../components/button/ConfirmButton';
import { useNavigate } from 'react-router-dom';
import { useConfirm } from '../hooks/useConfirm.hook';
import {
  P3GreyTextStyled,
  P3WhiteTextStyled,
} from '../styles/text/P3Text.styled';

const ARSAuth = () => {
  const navigate = useNavigate();

  const goToNext = () => navigate('/account/success');
  const goToPrevious = () => navigate(-1);

  const confirmAction = useConfirm(
    '',
    goToNext,
    goToPrevious,
  );
  return (
    <div className="flex items-center justify-center h-screen w-screen">
      <BasicBox>
        <BackButton show={true} />
        <div className={P3WhiteTextStyled.class}>ARS인증</div>
        <div className={P3GreyTextStyled.class}>
          계좌 등록을 위해 ARS 인증이 필요합니다.
        </div>
        <div className={P3GreyTextStyled.class}>
          등록된 번호(010-0000-0000)로 진행합니다.
        </div>
        <div className={P3GreyTextStyled.class}>
          ARS 인증 완료 후 아래의 확인 버튼을 눌러주세요.
        </div>
        <BasicButton onClick={confirmAction}>인증 확인</BasicButton>
      </BasicBox>
    </div>
  );
};

export default ARSAuth;
