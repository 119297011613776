import React, { useState, useEffect, useRef } from 'react';
import { CustomNumberPad } from './detail/CustomNumberPad';
import { IPinInput } from '../../interface/Pin.interface';
import { PinRenderInput } from '../../styles/password/PWPage.styled';

const CustomPinInput: React.FC<IPinInput> = ({
  length,
  onReset,
  onPinsave,
  isInputDisabled = false,
  onClean
}) => {
  const [inputs, setInputs] = useState<Array<string>>(Array(length).fill(''));
  const divRefs = useRef<Array<React.RefObject<HTMLDivElement>>>(
    inputs.map(() => React.createRef<HTMLDivElement>()),
  );

  useEffect(() => {
    if (divRefs.current[0] && divRefs.current[0].current) {
      divRefs.current[0].current.focus();
    }
  }, []);

  useEffect(() => {
    if (onReset) {
      setInputs(Array(length).fill(''));
      setMaskedInputs(Array(length).fill(''));
    }
  }, [onReset, length]);

  useEffect(() => {
    if (onClean || !onClean) {
      setInputs(Array(length).fill(''));
      setMaskedInputs(Array(length).fill(''));
    }
  }, [onClean, length])

  const [maskedInputs, setMaskedInputs] = useState(Array(length).fill(''));
  const timeouts: any = useRef([]);

  useEffect(() => {
    return () => timeouts.current.forEach(clearTimeout);
  }, []);

  // 숫자 변환 핸들러
  const handleChange = (value: string, index: number) => {
    if (isInputDisabled) return;
    const newInputs = [...inputs];
    newInputs[index] = value;
    setInputs(newInputs);
    onPinsave?.(newInputs.join(''));
    if (isInputDisabled) return;

    if (timeouts.current[index]) {
      clearTimeout(timeouts.current[index]);
    }

    timeouts.current[index] = setTimeout(() => {
      setMaskedInputs((prev) => {
        const newMaskedInputs = [...prev];
        newMaskedInputs[index] = '•';
        return newMaskedInputs;
      });
    }, 500);

  };

  const numberClickHandler = (clickedNumber: string) => {
    if (isInputDisabled) return;
    if (clickedNumber === 'delete') {
      const lastFilledIndex = inputs.reduceRight(
        (acc, curr, idx) => (curr && acc === -1 ? idx : acc),
        -1,
      );

      if (lastFilledIndex !== -1) {
        const newInputs = [...inputs];
        const newMaskedInputs = [...maskedInputs];
        newInputs[lastFilledIndex] = '';
        newMaskedInputs[lastFilledIndex] = '';
        setInputs(newInputs);
        setMaskedInputs(newMaskedInputs);
        onPinsave?.(newInputs.join(''));
        clearTimeout(timeouts.current[lastFilledIndex]);
      }
    } else {
      const currentInputIndex = inputs.findIndex(
        (input: string) => input === '',
      );
      if (currentInputIndex !== -1) {
        handleChange(clickedNumber, currentInputIndex);
      }
    }
  };

  const renderInputs = () => {
    return inputs.map((input, index) => (
      <div
        key={index}
        onClick={() => divRefs.current[index].current}
        tabIndex={0}
        className={`${PinRenderInput.class} ${input ? 'border-red-500' : 'border-p3_grey'}`}
      >
        {maskedInputs[index] || input}
      </div>
    ));
  };
  return (
    <div className='flex items-center justify-center'>
      <div className="flex flex-col items-center gap-[2px]">
        <div className="flex justify-center items-center ">
          {renderInputs()}
        </div>
        <div>
          <CustomNumberPad onNumberClick={numberClickHandler} />
        </div>
      </div>
    </div>
  );
};

export default CustomPinInput;
