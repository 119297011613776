import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface purchaseTokenstate {
  purchaseToken: string;
}

const initialState = {
  purchaseToken: {
    purchaseToken: '',
  },
};

export const purchaseTokenSlice = createSlice({
  name: 'PurchaseToken',
  initialState,
  reducers: {
    setPurchaseToken: (state, action: PayloadAction<purchaseTokenstate>) => {
      state.purchaseToken = action.payload;
    },
  },
});

export const { setPurchaseToken } = purchaseTokenSlice.actions;

export default purchaseTokenSlice.reducer;
