import React, { useState, useEffect } from 'react';
import CustomNumber from './number/CustomNumber';
import { INumberPad } from '../../../interface/Pin.interface';
import { PWNumberpad } from '../../../styles/password/PWPage.styled';

export const CustomNumberPad: React.FC<INumberPad> = ({ onNumberClick }) => {
  const [shuffledNumbers, setShuffledNumbers] = useState<
    (string | JSX.Element)[]
  >([]);

  const deleteSVG: JSX.Element = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className='w-[1.75rem] tabletH:w-[clamp(1.75rem,5vw,2.75rem)]'
      viewBox="0 0 28 28"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.21094 6H9.62515H23.6252H24.6252V7V21V22H23.6252H9.62515H9.21094L8.91805 21.7071L1.91804 14.7071L1.21094 14L1.91804 13.2929L8.91805 6.29289L9.21094 6ZM10.0394 8L4.03936 14L10.0394 20H22.6252V8H10.0394ZM13.1252 9.96079L13.8323 10.6679L15.7502 12.5858L17.668 10.6679L18.3752 9.96079L19.7894 11.375L19.0823 12.0821L17.1644 14L19.0823 15.9179L19.7894 16.625L18.3752 18.0392L17.668 17.3321L15.7502 15.4142L13.8323 17.3321L13.1252 18.0392L11.7109 16.625L12.418 15.9179L14.3359 14L12.418 12.0821L11.7109 11.375L13.1252 9.96079Z"
        fill="#C2C2C2"
      />
    </svg>
  );

  useEffect(() => {
    const shuffleNumbers = () => {
      const numbers: (string | JSX.Element)[] = [
        '1',
        '2',
        '3',
        '4',
        '5',
        '6',
        '7',
        '8',
        '9',
        '0',
      ];
      numbers.sort(() => Math.random() - 0.5);
      numbers.splice(9, 0, '');

      numbers.push(deleteSVG);
      setShuffledNumbers(numbers);
    };

    shuffleNumbers();
  }, []);

  const deleteClick = () => {
    onNumberClick('delete');
  };

  return (
    <div className={PWNumberpad.class}>
      {shuffledNumbers.map((number, index) =>
        typeof number === 'string' ? (
          <CustomNumber
            key={index.toString()}
            number={number}
            onNumberClick={onNumberClick}
          />
        ) : (
          <button
            key="delete-icon"
            className="flex justify-center items-center"
            onClick={deleteClick}
          >
            {deleteSVG}
          </button>
        ),
      )}
    </div>
  );
};
