import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import BasicBox from '../../../components/box/basicBox/BasicBox';
import Spline from '@splinetool/react-spline';
import { P3StatusTextStyled } from '../../../styles/text/P3Text.styled';


const CashReciptSuccess = () => {
  const navigate = useNavigate();
  const PurchaseToken = useSelector(
    (state: RootState) => state.purchaseToken.purchaseToken,
  );
  // const { PurchaseToken } = location.state || {};

  // 2초 후에 이동
  useEffect(() => {
    if (!PurchaseToken) return;
    console.log(PurchaseToken);
    const timer = setTimeout(() => {
      navigate('/user/mypage', {
        state: {
          PurchaseToken: PurchaseToken,
        },
      });
    }, 2500);

    return () => clearTimeout(timer);
  }, [navigate, PurchaseToken]);



  return (
    <div className="flex items-center justify-center h-[100dvh]">
      <BasicBox>
        <div className="flex flex-col items-center justify-center h-screen">
          <Spline className='spline' ref={(node) => {
            if (node) {
              const canvas = node.querySelector('canvas')
              if (!canvas) return;

              const splineRoot = document.documentElement;
              splineRoot.style.setProperty('--min-size', '130px');
              splineRoot.style.setProperty('--preferred-size', '20vw');
              splineRoot.style.setProperty('--max-size', '250px');

              canvas.style.setProperty('width', 'clamp(var(--min-size), var(--preferred-size), var(--max-size))', 'important');
              canvas.style.setProperty('height', 'clamp(var(--min-size), var(--preferred-size), var(--max-size))', 'important');
            }
          }} scene="/animation/success.splinecode" />
          <p className={P3StatusTextStyled.class}>정보 수정이</p>
          <p className={P3StatusTextStyled.class}>완료되었습니다.</p>
        </div>
      </BasicBox>
    </div>
  );
};

export default CashReciptSuccess;
