import React, { useEffect } from 'react';
import BasicBox from '../../../components/box/basicBox/BasicBox';
import { SendMessage } from '../../../utils/iframe';
import Spline from '@splinetool/react-spline';
import { P3StatusTextStyled } from '../../../styles/text/P3Text.styled';


const PaymentCancelSuccess = () => {
  useEffect(() => {
    const timer = setTimeout(() => {
      SendMessage('error', null);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);



  return (
    <div className="flex items-center justify-center h-[100dvh]">
      <BasicBox>
        <div className="flex flex-col items-center justify-center h-screen">
          <Spline className='spline' ref={(node) => {
            if (node) {
              const canvas = node.querySelector('canvas')
              if (!canvas) return;

              const splineRoot = document.documentElement;
              splineRoot.style.setProperty('--min-size', '130px');
              splineRoot.style.setProperty('--preferred-size', '20vw');
              splineRoot.style.setProperty('--max-size', '250px');

              canvas.style.setProperty('width', 'clamp(var(--min-size), var(--preferred-size), var(--max-size))', 'important');
              canvas.style.setProperty('height', 'clamp(var(--min-size), var(--preferred-size), var(--max-size))', 'important');
            }
          }} scene="/animation/success.splinecode" />
          <br />
          <div className={P3StatusTextStyled.class}>결제가 취소되었어요!</div>
          <div className={P3StatusTextStyled.class}>다시 시도해주세요</div>
        </div>
      </BasicBox>
    </div>
  );
};

export default PaymentCancelSuccess;
