import React from 'react';
import { useEffect, useState } from 'react';
import { SendMessage } from '../utils/iframe';
import { useLocation, useNavigate } from 'react-router-dom';
import { MainAPI } from '../api/mainAPI';
import LoadingIndicator from '../components/lodingIndicator/LodingIndicator';
import { useDispatch } from 'react-redux';
import { setPurchaseToken } from '../features/purchaseToken.slice';
import { setP3Auth } from '../features/p3Slice';
import { mainAxiosErrorHandler } from '../utils/error/mainErrorHandler';
import PurchaseTokenError from './PurchaseTokenError';


const Main = () => {
  const navigate = useNavigate();
  const loaction = useLocation();
  const dispatch = useDispatch();

  const [encryptedPurchaseData, setEncryptedPurchaseData] =
    useState<string>('');

  // 여기서의 PurchaseToKen은 계좌등록이 완료된 후에 갱신된 PurchaseToken
  const { PurchaseToken } = loaction.state || {};
  const [isError, setIsError] = useState<boolean>(false);

  useEffect(() => {
    const loadPurchaseDataFromParam = () => {
      // 고객사 프론트에서 위젯 호출 시 파라미터로 암호화된 결제 데이터가 넘어옴.
      // 이 데이터가 없으면 고객사 프론트로 돌아가도록 처리
      const params = new URLSearchParams(window.location.search);
      const purchaseData = params.get('d') || '';

      // PurchaseToken의 존재 여부에 따라 토큰 처리
      // if (!PurchaseToken) {
      //   setEncryptedPurchaseData(purchaseData);
      // }

      const IS_DEV = false;

      if (!purchaseData) {
        if (IS_DEV) {
          // 개발 모드일 경우, 테스트 데이터로 결제 데이터
          // setEncryptedPurchaseData(
          //   '3YrRNJVeUArxFy8y8U2leFYkUzI9zKMe0AGYF0EHAkzMLk5cH6/oq/dI3LIG/TDz+48Q7kyQxt7tbtXWL4zINwHu5Nt78hU+B791t5JXTamNYpZfWvYpm5eK29I8F1mx/jWrKA/NrJgCcAyhDo5AuGBK6mJOiIBagBr/98FCrfpPdMUpYptUIuxHLluKUh+norF885vDocqXJmUkELs3exsG5hjli+2kk+2SLG4kJNbEpmcZHjLeqrxKfqOpWpmYkqH72X6FcUMrI0Dpn6ldkZqagKqp/AeSuVotqFzwxCDuiRxGQVVJBD+lD8lhdE4Kkt4wX1c0JLZltgGf4SLe5zWa73O9g0I8/hj9cqjQE42/57Ezq0QH44gF0tXoIXjxGKUOftwLlVty0j8CTAqTK/3AXGDxHbk7D6bOOFCIFIrKOf3lziLY1UtbomCp/EmnGZMw7UiC4AQlI8N4AuP9p7SO/BwPlAGs48U9129a6l0EnFZROKUD4pV+oqplcwFqSZnLuTuhXE0CQ3e/MtwPWu78cqGuL88mu4vKfIJgqK4WfRZ35cyYkpU9Jb5uZJcXWWBs4DU/HqaAoRBjtHyM5g==',
          // );
          return;
        }

        SendMessage('error', 'ERR_NO_PURCHASE_DATA');
        return;
      }
      setEncryptedPurchaseData(purchaseData);
      dispatch(setPurchaseToken({ purchaseToken: purchaseData }));
      console.log('Purchase data set from URL:', purchaseData);
    };

    loadPurchaseDataFromParam();
  }, []);

  const requestVaildata = async () => {
    if (PurchaseToken) {
      navigate('payment/main', { state: { PurchaseToken: PurchaseToken } });
    }
    console.log(PurchaseToken);

    if (!encryptedPurchaseData) return;

    try {
      const data = await MainAPI.login(encryptedPurchaseData);
      dispatch(setP3Auth(data.data.result));

      console.log(data);
      console.log(encryptedPurchaseData);
      navigate('/payment/main', {
        state: { PurchaseToken: encryptedPurchaseData },
      });
    } catch (error) {
      mainAxiosErrorHandler(error, navigate, encryptedPurchaseData);
      return;
    }
  };
  useEffect(() => {
    if (encryptedPurchaseData) {
      requestVaildata();
    }
    if (!encryptedPurchaseData) {
      const timer = setTimeout(() => {
        setIsError(true);
      }, 500);
      return () => clearTimeout(timer);
    }
  }, [encryptedPurchaseData]);

  if (isError) {
    return <PurchaseTokenError />;
  } else {
    return <LoadingIndicator />;
  }
};

export default Main;
