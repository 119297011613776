import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useConfirm } from '../../../hooks/useConfirm.hook';
import BasicBox from '../../../components/box/basicBox/BasicBox';
import BackButton from '../../../components/button/BackButton';
import { useEffect, useRef, useState } from 'react';
import {
  P3GreyTextStyled,
  P3SheetwhiteStyled,
  P3SheetGreystyled,
  P3WhiteTextStyled_mb,
} from '../../../styles/text/P3Text.styled';
import { motion } from 'framer-motion';
import PWPageInteraction from '../../../components/Interection/PWSettingPageInteraction';
import CustomPinInput from '../../../contents/pin';
import { MainAPI } from '../../../api/mainAPI';
import { useModal } from '../../../hooks/useModal.hook';
import { ModalContainerLayout } from '../../../styles/modal/InfoModal.styled';
import { useOutsideClick } from '../../../hooks/useOutsideClick.hook';
import { InModalGreyBorderLine } from '../../../styles/line/BorderLine.styled';
import { btnMain, btnModalGrey } from '../../../styles/btn/Btn.styled';
import ModalInteraction from '../../../components/Interection/ModalInteraction';
import { useDispatch, useSelector } from 'react-redux';
import { setP3Auth } from '../../../features/p3Slice';
import Lottie from 'lottie-react';
import LottieLoading from '../../../styles/icons/Loading.json';
import axios, { AxiosError } from 'axios';
import BasicButton from '../../../components/button/ConfirmButton';
import { IServerError } from '../../../interface/serverError.interface';
import { RootState } from '../../../store/store';
import { btnLoading } from '../../../styles/loading/Loading.styled';

const PasswordSettingInput = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const pinInputRef = useRef<HTMLInputElement>(null);
  const modalRef = useRef<HTMLDivElement>(null);
  const [pinsave, setPinSave] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isInputDisabled, setIsInputDisabled] = useState<boolean>(false);
  const [paymentMethod, setPaymentMethod] = useState<string>('OPENBANKING');
  const [confirmBtnDisabled, setConfirmBtnDisabled] = useState<boolean>(true);
  const [cleanFlag, setCleanFlag] = useState<boolean>(false);
  const { modalIsOpen, openModal, closeModal } = useModal();
  const dispatch = useDispatch();
  const [showBackButton, setShowBackButton] = useState(true);

  const p3Auth = useSelector((state: RootState) => state.p3Auth);

  const PurchaseToken = useSelector(
    (state: RootState) => state.purchaseToken.purchaseToken,
  );

  const closeModalHandler = () => {
    setCleanFlag((current) => !current);
    closeModal();
  };

  useOutsideClick(modalRef, closeModalHandler, modalIsOpen);

  const goToPrevious = () =>
    navigate('/password/setting', {
      state: {
        PollingCode: PollingCode,
        PhoneAuthedCode: PhoneAuthedCode,
      },
    });

  const { PollingCode, PhoneAuthedCode, FirstPinsave } = location.state || {};

  useEffect(() => {
    if (pinInputRef.current) {
      pinInputRef.current?.focus();
    }
  }, [pinInputRef]);

  const completeHandler = (value: string) => {
    setPinSave(value);
    if (value.length >= 4) {
      setConfirmBtnDisabled(false);
    } else {
      setConfirmBtnDisabled(true);
    }
  };

  useEffect(() => {
    console.log('PollingCode:', PollingCode);
    console.log('PurchaseToken:', PurchaseToken);
    console.log('PhoneAuthedCode:', PhoneAuthedCode);
    console.log('FirstPinsave', FirstPinsave);
  }, [PollingCode, PurchaseToken, PhoneAuthedCode, FirstPinsave]);

  const handleSummit = async () => {
    setIsLoading(true);
    setShowBackButton(false);
    setIsInputDisabled(true);

    if (FirstPinsave !== pinsave) {
      openModal();
      setShowBackButton(true);
      setIsLoading(false);
      setIsInputDisabled(false);
      return;
    }

    if (!PollingCode || !PurchaseToken) {
      console.log('필수 정보 누락');
      {
        modalIsOpen && (
          <div
            className={`${ModalContainerLayout.class} pointer-events-auto`}
            onClick={(e) => e.stopPropagation()}
            onTouchStart={(e) => e.stopPropagation()}
          >
            <ModalInteraction
              ref={modalRef}
              ModalVariants={{
                hidden: {
                  y: '100vh',
                  opacity: 0,
                },
                visible: {
                  y: 0,
                  opacity: 1,
                  transition: { type: 'spring', stiffness: 250, damping: 30 },
                },
              }}
            >
              <h2 className={P3SheetwhiteStyled.class}>필수정보누락</h2>
              <p className={P3SheetGreystyled.class}>
                시간이 경과하여 정보를 잃었습니다
              </p>
              <p className={P3SheetGreystyled.class}>처음으로 돌아갑니다.</p>
              <div className={InModalGreyBorderLine.class}></div>
              <button
                className={btnModalGrey.class}
                onClick={modalConfirmHandler}
              >
                확인
              </button>
            </ModalInteraction>
          </div>
        );
      }
      navigate('/');
      return;
    }
    try {
      // 회원가입
      const res = await MainAPI.register(
        PurchaseToken.purchaseToken,
        PhoneAuthedCode,
        pinsave,
        PollingCode,
        paymentMethod,
      );

      const userNameRes = await MainAPI.purchaseRetrieve(
        PurchaseToken.purchaseToken,
        p3Auth,
      );
      const userName = userNameRes.data.result.methods[0].holderName;

      dispatch(setP3Auth(res.data.result));
      console.log(res);

      navigate('/account/success', {
        state: {
          PhoneAuthedCode: PhoneAuthedCode,
          userName: userName,
        },
      });
    } catch (error) {
      if (axios.isAxiosError<IServerError>(error)) {
        const axiosError = error as AxiosError<IServerError>;
        if (axiosError.response) {
          const serverError: IServerError = axiosError.response.data;
          // 이미 가입된 유저
          if (serverError.statusCode === 1016) {
            alreadyExistAccountErrorHandler();
          }
          // 알 수 없는 에러
          if (serverError.statusCode === 5000) {
            navigate('/account/register_request', {
              state: {
                PhoneAuthedCode: PhoneAuthedCode,
              },
            });
            // navigate('/', {
            //   state: {
            //     PurchaseToken: PurchaseToken,
            //   },
            // });
          }
        }
      }
      console.log(error);
      console.log('Error occured while registering');
    }

    console.log('Success! Redirection to purchase page');
  };
  const alreadyExistAccountErrorHandler = async () => {
    try {
      const res = await MainAPI.login(
        PurchaseToken.purchaseToken,
        PhoneAuthedCode,
      );
      if (res.data.statusCode === 200) {
        dispatch(setP3Auth(res.data.result));
        navigate('/payment/main', {
          state: {
            PollingCode: PollingCode,
            PhoneAuthedCode: PhoneAuthedCode,
          },
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const modalConfirmHandler = () => {
    closeModal();
    setCleanFlag((current) => !current);
    navigate('/password/setting_input', {
      state: {
        PollingCode: PollingCode,
        PhoneAuthedCode: PhoneAuthedCode,
        FirstPinsave: FirstPinsave,
      },
    });
  };

  const confirmAction = useConfirm('', handleSummit, goToPrevious);

  return (
    <div className="flex items-center justify-center h-[100dvh] w-screen">
      <BasicBox>
        <BackButton show={showBackButton} onClick={goToPrevious} />
        <PWPageInteraction
          PageTransition={{
            initial: { opacity: 0.5 }, // 초기 상태에서는 보이지 않음
            animate: {
              opacity: 1,
              transition: {
                ease: [0.6, 0.9, 0.6, 0.5],
              },
            }, // 애니메이션을 통해 서서히 보이게 함
          }}
        >
          <div className={P3WhiteTextStyled_mb.class}>비밀번호 확인</div>
          <div className={P3GreyTextStyled.class}>
            입력한 비밀번호를 다시 한번 입력해주세요
          </div>

          <CustomPinInput
            length={4}
            onClean={cleanFlag}
            onPinsave={completeHandler}
            isInputDisabled={isInputDisabled}
          />
        </PWPageInteraction>

        {modalIsOpen && (
          <div
            className={`${ModalContainerLayout.class} pointer-events-auto`}
            onClick={(e) => e.stopPropagation()}
            onTouchStart={(e) => e.stopPropagation()}
          >
            <ModalInteraction
              ref={modalRef}
              ModalVariants={{
                hidden: {
                  y: '100vh',
                  opacity: 0,
                },
                visible: {
                  y: 0,
                  opacity: 1,
                  transition: { type: 'spring', stiffness: 250, damping: 30 },
                },
              }}
            >
              <h2 className={P3SheetwhiteStyled.class}>
                비밀번호가 일치하지 않습니다
              </h2>
              <p className={P3SheetGreystyled.class}>입력하신 비밀번호를</p>
              <p className={P3SheetGreystyled.class}>
                다시 한 번 확인해 주세요
              </p>
              <div className={InModalGreyBorderLine.class}></div>
              <button
                className={btnModalGrey.class}
                onClick={modalConfirmHandler}
              >
                확인
              </button>
            </ModalInteraction>
          </div>
        )}
        <div className="mt-auto"></div>

        {isLoading ? (
          <motion.button
            className={btnMain.class}
            animate={{ opacity: isLoading ? 0.7 : 1 }}
          >
            <Lottie
              animationData={LottieLoading}
              loop={true}
              className={btnLoading.class}
            />
          </motion.button>
        ) : (
          <BasicButton onClick={confirmAction} disabled={confirmBtnDisabled}>
            설정하기
          </BasicButton>
        )}
      </BasicBox>
    </div>
  );
};

export default PasswordSettingInput;
