import React from 'react';
import { useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useConfirm } from '../../../../hooks/useConfirm.hook';
import BasicBox from '../../../../components/box/basicBox/BasicBox';
import BackButton from '../../../../components/button/BackButton';
import BasicButton from '../../../../components/button/ConfirmButton';
import {
  GreyBorderLine,
  RedBorderLine,
} from '../../../../styles/line/BorderLine.styled';
import {
  P3WhiteTextStyled,
  P3GreyLabelStyled,
  P3GreyInputStyled,
  P3GreyLabelStyled_2,
} from '../../../../styles/text/P3Text.styled';
import PageInteraction from '../../../../components/Interection/PageInteraction';
import { useModal } from '../../../../hooks/useModal.hook';
import Lottie from 'lottie-react';
import LottieLoading from '../../../../styles/icons/Loading.json';
import { Agreements } from '../../../../interface/checkbox.interface';
import { inputLoading } from '../../../../styles/loading/Loading.styled';

const CashReciptIssue = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const modalRef = useRef<HTMLDivElement>(null);
  const [numberInput, setNumberInput] = useState<string>(''); // 가져온 현금영수증 번호 담을것
  const [overMsg, setOverMsg] = useState<boolean>(false);
  const { modalIsOpen, openModal, closeModal } = useModal();
  const [confirmBtnDisabled, setConfirmBtnDisabled] = useState<boolean>(false);
  const [agreements, setAgreements] = useState<Agreements>({
    personalReciptAgreed: false,
    enterpriseReciptAgreed: false,
  });

  const agreementChangeHandler = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { name, checked } = event.target;

    const checkout: keyof Agreements =
      name === 'personalReciptAgreed'
        ? 'enterpriseReciptAgreed'
        : 'personalReciptAgreed';

    setAgreements({
      ...agreements,
      [name]: checked,
      [checkout]: !checked ? false : agreements[checkout],
    });
  };

  const { PurchaseToken } = location.state || {};

  useEffect(() => {
    setNumberInput('010-2057-9477');
    if (!numberInput) {
      setConfirmBtnDisabled(true);
    } else {
      setConfirmBtnDisabled(false);
    }
    // API 넘어오면
    // numberInput이 있는 경우
    // 체크박스 개인인지 기업인지 확인하고
    // 확인한 박스에 체크 하고
    // 다른 쪽 disable 해야 됌
  }, [numberInput]);

  const goToPrevious = () => navigate(-1);
  const goToNext = () => navigate('/cashRecipt/info_edit');
  const handleBackClick = () => {
    navigate('/user/mypage');
  };

  const confirmAction = useConfirm('', goToNext, goToPrevious);

  return (
    <div className="flex items-center justify-center h-screen w-screen">
      <BasicBox>
        <BackButton show={true} onClick={handleBackClick} />
        <PageInteraction
          PageTransition={{
            initial: { opacity: 0.5 },
            animate: {
              opacity: 1,
              transition: {
                ease: [0.6, 0.9, 0.6, 0.5],
              },
            },
          }}
        >
          {/* {modalIsOpen && (
            <div
              className={AlertContainerLayout.class}
              onClick={(e) => e.stopPropagation()}
              onTouchStart={(e) => e.stopPropagation()}
              style={{ pointerEvents: 'auto' }}
            >
              <AlertInteraction
                ref={modalRef}
                AlertVariants={{
                  hidden: {
                    scale: 0.75,
                    opacity: 0,
                  },
                  visible: {
                    scale: 1,
                    opacity: 1,
                    transition: {
                      duration: 0.2,
                      ease: 'easeInOut',
                    },
                  },
                }}
              >
                {overMsg ? (
                  <React.Fragment>
                    <h3 className={P3AlertTextWhite.class}>연속적인 메세지</h3>
                    <p className={P3AlertTextgrey.class}>
                      과도한 요청은 서버를 아프게 해요
                    </p>
                    <p className={P3AlertTextgrey.class}>
                      메세지는 1번씩만 요청해 주세요
                    </p>
                    <div className={InAlertGreyBorderLine.class}></div>
                    <button className={btnModalGrey.class} onClick={closeModal}>
                      확인
                    </button>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <h3 className={P3AlertTextWhite.class}>
                      휴대폰 번호가 일치하지 않아요
                    </h3>
                    <p className={P3AlertTextgrey.class}>
                      입력하신 휴대폰 번호를
                    </p>
                    <p className={P3AlertTextgrey.class}>
                      다시 한 번 확인해 주세요
                    </p>
                    <div className={InAlertGreyBorderLine.class}></div>
                    <button className={btnModalGrey.class} onClick={closeModal}>
                      확인
                    </button>
                  </React.Fragment>
                )}
              </AlertInteraction>
            </div>
          )} */}

          <div className={P3WhiteTextStyled.class}>현금영수증 발급 설정</div>
          <label className={P3GreyLabelStyled.class}>
            나의 현금영수증 정보 확인
          </label>
          <div className="flex mt-3 mb-3">
            <input
              type="checkbox"
              id="personal"
              name="personalReciptAgreed"
              checked={agreements.personalReciptAgreed}
              onChange={agreementChangeHandler}
              required
              disabled={agreements.enterpriseReciptAgreed}
              className="mr-1"
            />
            <label
              htmlFor="personal"
              className={`${P3GreyLabelStyled_2.class} mr-9`}
            >
              개인(소득공제)
            </label>
            <input
              type="checkbox"
              id="enterprise"
              name="enterpriseReciptAgreed"
              required
              checked={agreements.enterpriseReciptAgreed}
              onChange={agreementChangeHandler}
              disabled={agreements.personalReciptAgreed}
              className="mr-1"
            />
            <label htmlFor="enterprise" className={P3GreyLabelStyled_2.class}>
              기업(지출증빙)
            </label>
          </div>
          {numberInput ? (
            <div
              className={`${numberInput.length === 13
                ? RedBorderLine.class
                : GreyBorderLine.class
                } placeholder-p3_placeholder ${P3GreyInputStyled.class}`}
            >
              {numberInput}
            </div>
          ) : (
            <>
              <Lottie
                animationData={LottieLoading}
                loop={true}
                className={inputLoading.class}
              />
              <div className={GreyBorderLine.class}></div>
            </>
          )}
          <p className={P3GreyLabelStyled_2.class}>
            기존 발급 건에 대해 수정 또는 취소 희망시
          </p>
          <p className={P3GreyLabelStyled_2.class}>
            고객센터(1588-5028)로 문의 바랍니다.
          </p>
        </PageInteraction>

        <div className="flex-grow"></div>

        <BasicButton onClick={confirmAction} disabled={confirmBtnDisabled}>
          정보 수정
        </BasicButton>
      </BasicBox>
    </div >
  );
};

export default CashReciptIssue;
