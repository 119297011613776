import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import BasicBox from '../../../components/box/basicBox/BasicBox';
import BackButton from '../../../components/button/BackButton';
import BasicButton from '../../../components/button/ConfirmButton';
import { useEffect, useRef, useState } from 'react';
import {
  InAlertGreyBorderLine,
  InModalGreyBorderLine,
  InModalLengthGreyBorderLine,
} from '../../../styles/line/BorderLine.styled';
import {
  P3WhiteTextStyled_2,
  P3SheetwhiteStyled,
  P3GreyTextStyled_2,
  P3GreyTextStyled_3,
  P3AlertTextWhite,
  P3AlertTextgrey,
} from '../../../styles/text/P3Text.styled';
import ModalInteraction from '../../../components/Interection/ModalInteraction';
import PWPageInteraction_2 from '../../../components/Interection/PWInputPageInteraction_2';
import CustomPinInput from '../../../contents/pin';
import { MainAPI } from '../../../api/mainAPI';
import {
  AlertContainerLayout,
  ModalContainerLayout,
} from '../../../styles/modal/InfoModal.styled';
import { useOutsideClick } from '../../../hooks/useOutsideClick.hook';
import { btnModalGrey, btnSheet } from '../../../styles/btn/Btn.styled';
import axios, { AxiosError } from 'axios';
import { useSelector } from 'react-redux';
import AlertInteraction from '../../../components/Interection/AlertInteraction';
import { IServerError } from '../../../interface/serverError.interface';
import { RootState } from '../../../store/store';
import { SendMessage } from '../../../utils/iframe';

const PaymentPasswordInput = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [pinsave, setPinSave] = useState<string>('');
  const { MethodType, BankName, TotalCharge, MethodId } = location.state || {};
  const BackModalRef = useRef<HTMLDivElement>(null);
  const PasswordModalRef = useRef<HTMLDivElement>(null);
  const ErrorMsgModalRef = useRef<HTMLDivElement>(null);
  const [resetFlag, setResetFlag] = useState<number>(1);
  const [cleanFlag, setCleanFlag] = useState<boolean>(false);
  const [signature, setSignature] = useState<string>('');
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [isInputDisabled, setIsInputDisabled] = useState<boolean>(false);
  const [confirmBtnDisabled, setConfirmBtnDisabled] = useState<boolean>(true);

  const goToNext = () =>
    navigate('/payment/success', { state: { signature: signature } });
  // const goToPrevious = () => navigate(-1);

  // const confirmAction = useConfirm('', goToNext, goToPrevious);

  // 뒤로가기 클릭 시 모달 창
  const [backBtnModalIsOpen, setBackBtnModalIsOpen] = useState<boolean>(false);
  const BackOpenModal = () => setBackBtnModalIsOpen(true);
  const BackCloseModal = () => {
    setBackBtnModalIsOpen(false), setCleanFlag((current) => !current);
  };
  // 모달창 취소 훅
  useOutsideClick(BackModalRef, BackCloseModal, backBtnModalIsOpen);

  const p3Auth = useSelector((state: RootState) => state.p3Auth);

  const PurchaseToken = useSelector(
    (state: RootState) => state.purchaseToken.purchaseToken,
  );

  const modalCancelHandler = () => {
    BackCloseModal();
    navigate('/payment/cancel_success');
  };
  const modalConfirmHandler = () => {
    setCleanFlag((current) => current);
    console.log(cleanFlag);
    BackCloseModal();
  };
  // 비밀번호 틀렸을 시 모달 창
  const [passwordErrorModalIsOpen, setPasswordErrorModalIsOpen] =
    useState<boolean>(false);
  const PasswordOpenModal = () => setPasswordErrorModalIsOpen(true);
  const PasswordCloseModal = () => {
    setPasswordErrorModalIsOpen(false);
    setCleanFlag((current) => !current);
    setResetFlag((prev) => prev + 1);
    setIsInputDisabled(false);
    console.log(resetFlag);
    if (resetFlag >= 5) {
      navigate('/password/error');
    }
  };
  useOutsideClick(
    PasswordModalRef,
    PasswordCloseModal,
    passwordErrorModalIsOpen,
  );
  // 에러 메세지 모달 창
  const [ErrorMsgModalIsOpen, setErrorMsgModalIsOpen] =
    useState<boolean>(false);
  const ErrorMsgOpenModal = () => setErrorMsgModalIsOpen(true);
  const ErrorMsgCloseModal = () => {
    setErrorMsgModalIsOpen(false);
    setCleanFlag((current) => !current);
    setIsInputDisabled(false);
  };
  // useQutsideClick(ErrorMsgModalRef, ErrorMsgCloseModal, ErrorMsgModalIsOpen);

  const completeHandler = (value: string) => {
    console.log('입력된 비번', value);
    setPinSave(value);
    if (value.length >= 4) {
      setConfirmBtnDisabled(false);
    } else {
      setConfirmBtnDisabled(true);
    }
  };

  const lastConfirmHandler = () => {
    setIsInputDisabled(true);
    console.log('입력된 비번', pinsave);
    if (pinsave) Purchase(pinsave);
  };

  useEffect(() => {
    console.log('isInputDisabled 변경됨:', isInputDisabled);
  }, [isInputDisabled]);

  useEffect(() => {
    if (!signature) return;
    goToNext();
  }, [signature]);

  useEffect(() => {
    console.log(cleanFlag);
  }, [cleanFlag]);

  useEffect(() => {
    console.log('confirmBtnDisabled', confirmBtnDisabled);
  }, [confirmBtnDisabled]);

  // 구매 요청
  const Purchase = async (pin: string) => {
    try {
      if (pin) {
        const res = await MainAPI.purchase(
          PurchaseToken.purchaseToken,
          pin,
          MethodType,
          MethodId,
          p3Auth,
        );
        console.log('Purchase res', res.data);
        if (res.data.statusCode === 200) {
          setIsInputDisabled(true);
          setSignature(res.data.result.signature);
          if (signature) goToNext();
        }
      }
    } catch (error) {
      if (axios.isAxiosError<IServerError>(error)) {
        const axiosError = error as AxiosError<IServerError>;
        if (axiosError.response) {
          const serverError: IServerError = axiosError.response.data;
          console.log(serverError);
          // 비밀번호 틀림 오류
          if (serverError.statusCode === 1012) {
            PasswordOpenModal();
          }
          if (serverError.statusCode === 5000) {
            ErrorMsgOpenModal();
            setErrorMsg(
              '필수 정보가 존재하지 않습니다.|결제페이지로 이동합니다.',
            );
            SendMessage('error', null);
          }
          if (serverError.statusCode === 1013) {
            ErrorMsgOpenModal();
            setErrorMsg('사용자를 찾을 수 없습니다.|결제 페이지로 이동합니다.');
            navigate('/');
          }
          if (serverError.statusCode === 1014) {
            ErrorMsgOpenModal();
            setErrorMsg(
              '선택된 결제 수단으로 결제할 수 없습니다.|결제 수단을 확인 후 다시 시도해 주세요.',
            );
            setCleanFlag((current) => !current);
            setConfirmBtnDisabled(true);
          }
          if (serverError.statusCode === 1006) {
            ErrorMsgOpenModal();
            setErrorMsg(
              '오픈 뱅킹 API 에러입니다.|오픈뱅킹 고객센터에 문의해 주세요.',
            );
            setCleanFlag((current) => !current);
            setConfirmBtnDisabled(true);
          }
          if (serverError.statusCode === 2001) {
            ErrorMsgOpenModal();
            setErrorMsg(
              '잘못 된 결제 수단 형식을 입력하였습니다.|결제 수단을 확인 후 다시 시도해 주세요.',
            );
            setCleanFlag((current) => !current);
            setConfirmBtnDisabled(true);
          }
        }
      }
    }
  };

  // 비밀번호 틀렸을 때
  const InPasswordModalHandler = () => {
    PasswordCloseModal();
  };
  // 에러 메세지 모달창 확인 버튼
  const InErrorMsgModalConfirm = () => {
    ErrorMsgCloseModal();
  };

  useEffect(() => {
    console.log('PurchaseToken:', PurchaseToken);
    console.log('MethodType:', MethodType);
    console.log('BankName:', BankName);
    console.log('TotalCharge:', TotalCharge);
    console.log('methodID', MethodId);
  }, [PurchaseToken, MethodType, BankName, TotalCharge, MethodId]);

  return (
    <div className="flex items-center justify-center h-[100dvh] w-screen">
      <BasicBox>
        <BackButton show={true} onClick={BackOpenModal} />
        {/* 뒤로가기 클릭시 띄울 모달 창 */}
        {backBtnModalIsOpen && (
          <div
            className={`${ModalContainerLayout.class} pointer-events-auto`}
            onClick={(e) => e.stopPropagation()}
            onTouchStart={(e) => e.stopPropagation()}
          >
            <ModalInteraction
              ref={BackModalRef}
              ModalVariants={{
                hidden: {
                  y: '100vh',
                  opacity: 0,
                },
                visible: {
                  y: 0,
                  opacity: 1,
                  transition: { type: 'spring', stiffness: 250, damping: 30 },
                },
              }}
            >
              <h2 className={P3SheetwhiteStyled.class}>결제 취소</h2>
              <p className="text-[0.875rem] tabletH:text-[clamp(0.875rem,3vw,1.25rem)] text-p3_grey">
                이전화면으로 이동하면 결제가 취소됩니다.
              </p>
              <p className="text-[0.875rem] tabletH:text-[clamp(0.875rem,3vw,1.25rem)] text-p3_grey">결제 취소 하시겠습니까?</p>
              <div className={InModalGreyBorderLine.class}></div>
              <div className="flex justify-around gap-x-3">
                <button
                  onClick={modalConfirmHandler}
                  className={`${btnSheet.class} text-p3_grey`}
                >
                  결제하기
                </button>
                <div className={InModalLengthGreyBorderLine.class}></div>
                <button
                  className={`${btnSheet.class} text-p3_key_color `}
                  onClick={modalCancelHandler}
                >
                  결제취소
                </button>
              </div>
            </ModalInteraction>
          </div>
        )}

        {/* 비밀번호 틀렸을 시 띄울 모달창 */}
        {passwordErrorModalIsOpen && (
          <div
            className={`${ModalContainerLayout.class} pointer-events-auto`}
            onClick={(e) => e.stopPropagation()}
            onTouchStart={(e) => e.stopPropagation()}
          >
            <ModalInteraction
              ref={PasswordModalRef}
              ModalVariants={{
                hidden: {
                  y: '100vh',
                  opacity: 0,
                },
                visible: {
                  y: 0,
                  opacity: 1,
                  transition: { type: 'spring', stiffness: 250, damping: 30 },
                },
              }}
            >
              <br />
              <p className={P3WhiteTextStyled_2.class}>비밀번호 5회 오류 시</p>
              <br />
              <p className={P3WhiteTextStyled_2.class}>
                보안을 위해 결제가 제한됩니다
              </p>
              <br />
              <div className={InModalGreyBorderLine.class}></div>
              <button
                className={btnModalGrey.class}
                onClick={InPasswordModalHandler}
              >
                확인
              </button>
            </ModalInteraction>
          </div>
        )}
        {/* 에러 메세지 처리 모달창 */}
        {ErrorMsgModalIsOpen && (
          <div
            className={`${AlertContainerLayout.class} pointer-events-auto`}
            onClick={(e) => e.stopPropagation()}
            onTouchStart={(e) => e.stopPropagation()}
          >
            <AlertInteraction
              ref={ErrorMsgModalRef}
              AlertVariants={{
                hidden: {
                  scale: 0.75,
                  opacity: 0,
                },
                visible: {
                  scale: 1,
                  opacity: 1,
                  transition: {
                    duration: 0.2,
                    ease: 'easeInOut',
                  },
                },
              }}
            >
              <h3 className={P3AlertTextWhite.class}>오류</h3>
              <p className={P3AlertTextgrey.class}>
                {errorMsg.split('|').map((msg, index, array) => (
                  <React.Fragment key={index}>
                    {msg}
                    {index < array.length - 1 && <br />}
                  </React.Fragment>
                ))}
              </p>
              <div className={InAlertGreyBorderLine.class}></div>
              <button
                className={btnModalGrey.class}
                onClick={InErrorMsgModalConfirm}
              >
                확인
              </button>
            </AlertInteraction>
          </div>
        )}

        <PWPageInteraction_2
          PageTransition={{
            initial: { opacity: 0.5 },
            animate: {
              opacity: 1,
              transition: {
                ease: [0.6, 0.9, 0.6, 0.5],
              },
            },
          }}
        >
          <div className={P3GreyTextStyled_3.class}>비밀번호</div>
          <div className={P3GreyTextStyled_2.class}>4자리 숫자</div>
          <CustomPinInput
            length={4}
            onReset={resetFlag}
            onPinsave={completeHandler}
            onClean={cleanFlag}
            isInputDisabled={isInputDisabled}
          />
        </PWPageInteraction_2>
        <div className="mt-auto"></div>
        <BasicButton onClick={lastConfirmHandler} disabled={confirmBtnDisabled}>
          확인
        </BasicButton>
      </BasicBox>
    </div>
  );
};

export default PaymentPasswordInput;
