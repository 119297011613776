import React, { RefObject, useEffect } from 'react';
// 모달 창 안에서 다른 곳 터치해도 취소 되는 훅
export const useOutsideClick = (
  ref: RefObject<HTMLElement>,
  closeModal: () => void,
  isOpen: boolean,
  addAccountButtonRef?: RefObject<HTMLElement>,
) => {
  useEffect(() => {
    if (!isOpen) return;

    const handleClickOutside = (event: MouseEvent | TouchEvent) => {
      if (addAccountButtonRef?.current?.contains(event.target as Node)) {
        addAccountButtonRef.current.click();
      } else if (ref.current && !ref.current.contains(event.target as Node)) {
        setTimeout(() => {
          closeModal();
        }, 200);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, [ref, closeModal, isOpen, addAccountButtonRef]);
};
