import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import BasicBox from '../../../components/box/basicBox/BasicBox';
import { SendMessage } from '../../../utils/iframe';
import Spline from '@splinetool/react-spline';
import { P3StatusTextStyled } from '../../../styles/text/P3Text.styled';

const PaymentSuccess = () => {
  const location = useLocation();
  const { signature } = location.state || {};

  useEffect(() => {
    console.log(signature);
    if (!signature) return;
    const timer = setTimeout(() => {
      SendMessage('success', signature);
    }, 2500);

    return () => clearTimeout(timer);
  }, [signature]);


  return (
    <div className="flex items-center justify-center h-[100dvh]">
      <BasicBox>
        <div className="flex flex-col items-center justify-center h-screen">
          <Spline className='spline' ref={(node) => {
            if (node) {
              const canvas = node.querySelector('canvas')
              if (!canvas) return;

              const splineRoot = document.documentElement;
              splineRoot.style.setProperty('--min-size', '130px');
              splineRoot.style.setProperty('--preferred-size', '20vw');
              splineRoot.style.setProperty('--max-size', '250px');

              canvas.style.setProperty('width', 'clamp(var(--min-size), var(--preferred-size), var(--max-size))', 'important');
              canvas.style.setProperty('height', 'clamp(var(--min-size), var(--preferred-size), var(--max-size))', 'important');
            }
          }} scene="/animation/success.splinecode" />
          <p className={P3StatusTextStyled.class}>결제가 완료되었어요!</p>
        </div>
      </BasicBox>
    </div>
  );
};

export default PaymentSuccess;
