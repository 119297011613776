import React from 'react';
import { SendMessage } from '../utils/iframe';

import BasicBox from '../components/box/basicBox/BasicBox';
import { P3StatusTextStyled } from '../styles/text/P3Text.styled';
import Spline from '@splinetool/react-spline';

const PurchaseTokenError = () => {
  return (
    <div className="flex items-center justify-center h-[100dvh]">
      <BasicBox>
        <div className="flex flex-col items-center justify-center h-screen">
          <Spline
            className="spline"
            ref={(node) => {
              if (node) {
                const canvas = node.querySelector('canvas');
                if (!canvas) return;

                const splineRoot = document.documentElement;
                splineRoot.style.setProperty('--min-size', '130px');
                splineRoot.style.setProperty('--preferred-size', '20vw');
                splineRoot.style.setProperty('--max-size', '250px');

                canvas.style.setProperty(
                  'width',
                  'clamp(var(--min-size), var(--preferred-size), var(--max-size))',
                  'important',
                );
                canvas.style.setProperty(
                  'height',
                  'clamp(var(--min-size), var(--preferred-size), var(--max-size))',
                  'important',
                );
              }
            }}
            scene="/animation/bang.splinecode"
          />
          <p className={P3StatusTextStyled.class}>잘못된 경로로 접근했어요</p>
          <p className={P3StatusTextStyled.class}>다시 시도해 주세요</p>
          {/* <p className="text-p3_key_color">구매처에 연락해 주세요</p>
            <p className="text-p3_key_color">1588-5028</p> */}
        </div>
      </BasicBox>
    </div>
  );
};

export default PurchaseTokenError;
