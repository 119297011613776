import React from 'react';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useConfirm } from '../../../../hooks/useConfirm.hook';
import BasicBox from '../../../../components/box/basicBox/BasicBox';
import BackButton from '../../../../components/button/BackButton';
import BasicButton from '../../../../components/button/ConfirmButton';
import {
  GreyBorderLine,
  RedBorderLine,
} from '../../../../styles/line/BorderLine.styled';
import {
  P3WhiteTextStyled,
  P3GreyLabelStyled,
  P3GreyInputStyled,
  P3GreyLabelStyled_2,
} from '../../../../styles/text/P3Text.styled';
import PageInteraction from '../../../../components/Interection/PageInteraction';
import { Agreements } from '../../../../interface/checkbox.interface';

const CashReciptInfoEdit = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [numberInput, setNumberInput] = useState<string>('');
  const [confirmBtnDisabled, setConfirmBtnDisabled] = useState<boolean>(false);

  const [agreements, setAgreements] = useState<Agreements>({
    personalReciptAgreed: false,
    enterpriseReciptAgreed: false,
  });

  const agreementChangeHandler = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { name, checked } = event.target;
    const checkout: keyof Agreements =
      name === 'personalReciptAgreed'
        ? 'enterpriseReciptAgreed'
        : 'personalReciptAgreed';

    setAgreements({
      ...agreements,
      [name]: checked,
      [checkout]: !checked ? false : agreements[checkout],
    });
  };

  const { PurchaseToken } = location.state || {};

  const goToPrevious = () => navigate(-1);
  const goToNext = () => navigate('/cashRecipt/success');
  const handleBackClick = () => {
    navigate('/cashRecipt/issue');
  };

  const confirmAction = useConfirm('', goToNext, goToPrevious);

  // 개인인지 사업자인지 체크박스 확인하고 @@@@@@@@@
  // 길이마다 다르게 표시 해야 됌 @@@@@@@@@@@@
  // 길이가 정확하면 confirmBtnDisabled true 설정 @@@@@@@@@@
  const inputChangeHandler = (e: any) => {
    const { value } = e.target;
    // 숫자 말고는 타이핑 할 수 없게 하는 정규식
    const onlyNums = value.replace(/[^\d]/g, '');

    if (onlyNums.length <= 3) {
      setNumberInput(onlyNums);
    } else if (onlyNums.length <= 7) {
      setNumberInput(`${onlyNums.slice(0, 3)}-${onlyNums.slice(3)}`);
    } else if (onlyNums.length <= 11) {
      setNumberInput(
        `${onlyNums.slice(0, 3)}-${onlyNums.slice(3, 7)}-${onlyNums.slice(7)}`,
      );
    }
  };

  return (
    <div className="flex items-center justify-center h-[100dvh] w-screen">
      <BasicBox>
        <BackButton show={true} onClick={handleBackClick} />
        <PageInteraction
          PageTransition={{
            initial: { opacity: 0.5 },
            animate: {
              opacity: 1,
              transition: {
                ease: [0.6, 0.9, 0.6, 0.5],
              },
            },
          }}
        >
          <div className={P3WhiteTextStyled.class}>현금영수증 정보 수정</div>
          <label
            className={P3GreyLabelStyled.class}
          >
            나의 현금영수증 정보 수정
          </label>
          <div className="flex mt-3 mb-3">
            <input
              type="checkbox"
              id="personal"
              name="personalReciptAgreed"
              checked={agreements.personalReciptAgreed}
              onChange={agreementChangeHandler}
              required
              disabled={agreements.enterpriseReciptAgreed}
              className="mr-1"
            />
            <label
              htmlFor="personal"
              className={`${P3GreyLabelStyled_2.class} mr-9`}
            >
              개인(소득공제)
            </label>
            <input
              type="checkbox"
              id="enterprise"
              name="enterpriseReciptAgreed"
              required
              checked={agreements.enterpriseReciptAgreed}
              onChange={agreementChangeHandler}
              disabled={agreements.personalReciptAgreed}
              className="mr-1"
            />
            <label htmlFor="enterprise" className={P3GreyLabelStyled_2.class}>
              기업(지출증빙)
            </label>
          </div>
          <input
            type="tel"
            pattern="\d*"
            placeholder="010-1234-5678"
            value={numberInput}
            onChange={inputChangeHandler}
            // 사업자번호 10자리 일 경우, 핸드폰번호 11자리일 경우에 대한 처리 여기서  @@@@@@@@@@
            className={`${numberInput.length === 13
              ? RedBorderLine.class
              : GreyBorderLine.class
              } placeholder-p3_placeholder ${P3GreyInputStyled.class}`}
          />

        </PageInteraction>

        <div className="mt-auto"></div>

        <BasicButton onClick={confirmAction} disabled={confirmBtnDisabled}>
          확인
        </BasicButton>
      </BasicBox>
    </div>
  );
};

export default CashReciptInfoEdit;
