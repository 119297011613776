import React from 'react';
import Lottie from 'lottie-react';
import ErrorLoading from '../../styles/icons/ErrorLoading.json';
const LoadingIndicator = () => {
  return (
    <div className="flex items-center justify-center h-screen w-screen">
      <Lottie
        animationData={ErrorLoading}
        loop={true}
        className='w-[300px] h-[300px]'
      />
    </div>
  );
};

export default LoadingIndicator;
