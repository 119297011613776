import React from 'react';
import BasicBox from '../../components/box/basicBox/BasicBox';
import BackButton from '../../components/button/BackButton';
import BasicButton from '../../components/button/ConfirmButton';
import { useLocation, useNavigate } from 'react-router-dom';
import { useConfirm } from '../../hooks/useConfirm.hook';
import {
  P3GreyTextStyled,
  P3WhiteTextStyled_mb,
} from '../../styles/text/P3Text.styled';
import { useOpenbankingLink } from '../../hooks/useOpenbankingLink';
import { useEffect, useState } from 'react';
import PageInteraction from '../../components/Interection/PageInteraction';
import { GetCodeDto } from '../../api/interfaces/pollingAPI';
import { PollingAPI } from '../../api/pollingAPI';
import { OPENBANKING_REDIRECT_URL } from '../../constants';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';

const AccountRegisterRequest = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { PhoneAuthedCode } = location.state || {};
  const [PollingCode, setPollingCode] = useState<GetCodeDto | any>();
  const [childWindow, setChildWindow] = useState<Window | null>(null);
  const { openbankingLink, uuid } = useOpenbankingLink(
    OPENBANKING_REDIRECT_URL,
  );
  const OPEN_BANKING_WINDOW_OPTIONS = 'top=10, left=10, width=500, height=800';
  const OPEN_BANKING_WINDOW_TITLE = '오픈뱅킹 인증';

  const PurchaseToken = useSelector(
    (state: RootState) => state.purchaseToken.purchaseToken,
  );

  const openBankingReqHandler = () => {
    const windowRef = window.open(
      openbankingLink,
      OPEN_BANKING_WINDOW_TITLE,
      OPEN_BANKING_WINDOW_OPTIONS,
    );
    if (windowRef) setChildWindow(windowRef);
  };

  // Polling Server에서 Code 가져오는 함수
  const getCodeFromPS = async () => {
    const res = await PollingAPI.getCode(uuid);
    console.log('pollingCode', res.data.code);

    if (!res.data.code) {
      console.log('PollingCode get Request failed');
      return;
    }

    console.log('PollingCode get Request Success');
    setPollingCode(res.data.code);
  };

  useEffect(() => {
    if (!PollingCode) return;

    if (PollingCode) {
      navigate('/password/setting', {
        state: {
          PollingCode: PollingCode,
          PhoneAuthedCode: PhoneAuthedCode,
        },
      });
    }
  }, [PollingCode]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (childWindow && childWindow.closed) {
        getCodeFromPS();
        console.log('오픈 뱅킹 창이 닫혔네?');
        clearInterval(intervalId);
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }, [childWindow]);

  useEffect(() => {
    console.log('PollingCode, purchaseData 모두 세팅 시작');
    console.log('PollingCode:', PollingCode);
    console.log('PurchaseToken:', PurchaseToken);
    console.log('PhoneAuthedCode:', PhoneAuthedCode);
    if (!PurchaseToken) {
      console.log('none PurchaseToken');
      return;
    }
    if (!PollingCode) {
      return;
    }
    console.log('PollingCode, purchaseData 모두 셋팅 완료');
  }, [PollingCode]);

  const confirmAction = useConfirm('', openBankingReqHandler, () =>
    navigate(-1),
  );

  const backBtnNavi = () => {
    navigate('/phoneAuth/main');
  };

  return (
    <div className='flex justify-center items-center h-[100dvh] w-screen'>
      <BasicBox>
        <BackButton show={true} onClick={backBtnNavi} />
        <PageInteraction
          PageTransition={{
            initial: { opacity: 0.5 }, // 초기 상태에서는 보이지 않음
            animate: {
              opacity: 1,
              transition: {
                ease: [0.6, 0.9, 0.6, 0.5],
              },
            }, // 애니메이션을 통해 서서히 보이게 함
          }}
        >
          <div className={P3WhiteTextStyled_mb.class}>계좌등록</div>
          <div className={`gap-1 ${P3GreyTextStyled.class}`}>
            <div>계좌 등록을 위한 단계에요</div>
            <div>오픈뱅킹 화면으로 안내드릴게요.</div>
            <div>이번 한 번의 등록만으로</div>
            <div>앞으로의 이용이 훨씬 간편해져요!</div>
          </div>
        </PageInteraction>
        <div className="mt-auto"></div>
        <BasicButton onClick={confirmAction}>등록하기</BasicButton>
      </BasicBox>
    </div>
  );
};

export default AccountRegisterRequest;
