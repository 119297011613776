import { configureStore } from '@reduxjs/toolkit';
import purchaseReducer from '../features/purchaseSlice';
import totalChargeReducer from '../features/totalChargeSlice';
import p3AuthReducer from '../features/p3Slice';
import phoneAuthReducer from '../features/phoneAuthSlice';
import purchaseTokenReducer from '../features/purchaseToken.slice';

export const store = configureStore({
  reducer: {
    purchase: purchaseReducer,
    price: totalChargeReducer,
    p3Auth: p3AuthReducer,
    phoneAuth: phoneAuthReducer,
    purchaseToken: purchaseTokenReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
