// 추후에 쓰일 페이지

import React from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useConfirm } from '../../hooks/useConfirm.hook';
import BasicBox from '../../components/box/basicBox/BasicBox';
import BackButton from '../../components/button/BackButton';
import {
  GreyBorderLine,
  RedBorderLine,
} from '../../styles/line/BorderLine.styled';
import BasicButton from '../../components/button/ConfirmButton';
import {
  P3WhiteTextStyled,
} from '../../styles/text/P3Text.styled';
import PageInteraction from '../../components/Interection/PageInteraction';

const P3AccountRegister = () => {
  const navigate = useNavigate();
  const [carrierDropdownOpen, setCarrierDropdownOpen] = useState<boolean>(false);
  const [selectedCarrier, setSelectedCarrier] = useState<string>('');
  const [numberInput, setNumberInput] = useState<string>('');

  const toggleDropdown = () => setCarrierDropdownOpen(!carrierDropdownOpen);

  const selectCarrier = (carrier: string) => {
    setSelectedCarrier(carrier);
    setCarrierDropdownOpen(false);
  };

  const goToNext = () => navigate('/phoneAuth/confirm');
  const goToPrevious = () => navigate(-1);

  const confirmAction = useConfirm(
    '',
    goToNext,
    goToPrevious,
  );

  return (
    <PageInteraction
      PageTransition={{
        initial: { opacity: 0.5 }, // 초기 상태에서는 보이지 않음
        animate: {
          opacity: 1,
          transition: {
            ease: [0.6, 0.9, 0.6, 0.5],
          },
        }, // 애니메이션을 통해 서서히 보이게 함
      }}
    >
      <div className="flex items-center justify-center h-[100dvh] w-screen">
        <BasicBox>
          <BackButton show={true} />
          <div className={P3WhiteTextStyled.class}>계좌등록</div>
          <label className="text-p3_grey">은행 선택</label>
          <div
            className={
              selectedCarrier.length
                ? RedBorderLine.class
                : GreyBorderLine.class
            }
          >
            <button className="w-full">
              <div onClick={toggleDropdown} className="flex justify-between">
                <span>{selectedCarrier || '은행 선택'}</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-5 h-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </div>
            </button>
          </div>
          {carrierDropdownOpen && (
            <div className="h-20 overflow-scroll cursor-pointer bg-p3_background 
            text-grey">
              <div
                onClick={() => selectCarrier('KB국민은행')}
                className="hover:text-p3_key_color"
              >
                KB국민은행
              </div>
              <div
                onClick={() => selectCarrier('중소기업은행')}
                className="hover:text-p3_key_color"
              >
                중소기업은행
              </div>
              <div
                onClick={() => selectCarrier('NH농협은행')}
                className="hover:text-p3_key_color"
              >
                NH농협은행
              </div>
              <div
                onClick={() => selectCarrier('SC제일은행')}
                className="hover:text-p3_key_color"
              >
                SC제일은행
              </div>
              <div
                onClick={() => selectCarrier('신한은행')}
                className="hover:text-p3_key_color"
              >
                신한은행
              </div>
              <div
                onClick={() => selectCarrier('하나은행')}
                className="hover:text-p3_key_color"
              >
                하나은행
              </div>
              <div className="border-b border-p3_grey"></div>
            </div>
          )}
          <label className="flex items-end h-12 text-p3_grey">계좌번호</label>
          <input
            type="tel"
            pattern="\d*"
            placeholder="번호 입력"
            value={numberInput}
            onChange={(e) => setNumberInput(e.target.value)}
            className={`${numberInput.length === 11 || numberInput.length === 10
              ? RedBorderLine.class
              : GreyBorderLine.class
              } bg-transparent placeholder-p3_placeholder h-9 outline-none`}
          />
          <div className="mt-auto"></div>

          <BasicButton onClick={confirmAction}>확인하기</BasicButton>
        </BasicBox>
      </div>
    </PageInteraction>
  );
};

export default P3AccountRegister;
