import { tw } from '../../utils/Tailwindest';

export const boxMain = tw.style({
  display: 'flex',
  flexDirection: 'flex-col',
  width: 'w-full',
  height: 'h-full',
  minHeight: "min-h-[420px]",
  maxWidth: 'max-w-[768px]',
  maxHeight: 'max-h-[1050px]',
  color: 'text-[#ffffff]',
  // backgroundColor:"bg-black",
  padding: 'p-6',
  borderStyle: 'border-none',
  overscrollBehavior: 'overscroll-y-none',

  '@phone': { padding: "phone:p-6" },
  '@tablet': { padding: "tablet:p-10" },

});
