import React, { useState } from "react";
import { motion } from "framer-motion";
import Lottie from "lottie-react";
import LottieLoading from "../../styles/icons/Loading.json";
import { IConfirmButton } from "../../interface/Btn.interface";
import { btnMain } from "../../styles/btn/Btn.styled";
import { btnLoading } from "../../styles/loading/Loading.styled";

const BasicButton: React.FC<IConfirmButton> = ({ children, onClick, disabled }) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (!isLoading && !disabled) {
      setIsLoading(true);
      setTimeout(() => {
        setIsLoading(false);
        if (onClick) onClick(e);
      }, 300);
    }
  };

  return (
    <motion.button
      onClick={handleClick}
      className={btnMain.class}
      animate={{ opacity: isLoading || disabled ? 0.7 : 1 }}
      disabled={isLoading || disabled}
    >
      {isLoading ? (
        <Lottie
          animationData={LottieLoading}
          loop={true}
          className={btnLoading.class}
        />
      ) : (
        children
      )}
    </motion.button>
  );
};

export default BasicButton;
