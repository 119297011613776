import React, { useEffect, useState } from 'react';
import BasicBox from '../../../components/box/basicBox/BasicBox';
import { P3StatusTextStyled } from '../../../styles/text/P3Text.styled';
import { SendMessage } from '../../../utils/iframe';
import Spline from '@splinetool/react-spline';

const PasswordError5Times = () => {
  const [fiveTimesError, setFiveTimesError] = useState<boolean>(true);

  useEffect(() => {
    setFiveTimesError(true);
    setTimeout(() => {
      SendMessage('error', null);
    }, 5000);
  }, [fiveTimesError]);


  return (
    <div className="flex items-center justify-center h-[100dvh]">
      <BasicBox>
        <div className="flex flex-col items-center justify-center h-screen">
          <div className='w-[130px] h-[130px]'>
            <Spline className='spline' ref={(node) => {
              if (node) {
                const canvas = node.querySelector('canvas')
                if (!canvas) return;

                const splineRoot = document.documentElement;
                splineRoot.style.setProperty('--min-size', '130px');
                splineRoot.style.setProperty('--preferred-size', '20vw');
                splineRoot.style.setProperty('--max-size', '250px');

                canvas.style.setProperty('width', 'clamp(var(--min-size), var(--preferred-size), var(--max-size))', 'important');
                canvas.style.setProperty('height', 'clamp(var(--min-size), var(--preferred-size), var(--max-size))', 'important');
              }
            }} scene="/animation/bang.splinecode" />
          </div>
          <p className={` ${P3StatusTextStyled.class}`}>
            비밀번호 5회 오류로
          </p>
          <p className={P3StatusTextStyled.class}>결제가 제한되었습니다</p>
          <br />
          <p className={`${P3StatusTextStyled.class} text-p3_key_color`}>고객센터 문의</p>
          <p className={`${P3StatusTextStyled.class} text-p3_key_color`}>1588-5028</p>
        </div>
      </BasicBox>
    </div>
  );
};

export default PasswordError5Times;
